import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, Edit, DateTimeInput, BooleanInput } from 'react-admin';

export const orderItemCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="item_id"  label="item id" type="number"/>
            <TextInput source="quantity"  label="quantity" type="number"/>
            <TextInput source="order_id"  label="order id" type="number"/>
            <TextInput source="vendor_id"  label="vendor id" type="number"/>
            <TextInput source="status"  label="order item status" type="number"/>
        </SimpleForm>
    </Create>
);

export const orderItemShow=()=>(
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="item_id" />
            <TextField source="quantity" />
            <TextField source="order_id" />
            <TextField source="vendor_id" />
            <TextField source="status" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        </SimpleShowLayout>
    </Show>
);

export const orderItemList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="item_id" />
            <TextField source="quantity" />
            <TextField source="order_id" />
            <TextField source="vendor_id" />
            <TextField source="status" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};

  export const orderItemEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="item_id"  label="item id" type="number"/>
            <TextInput source="quantity"  label="quantity" type="number"/>
            <TextInput source="order_id"  label="order id" type="number"/>
            <TextInput source="vendor_id"  label="vendor id" type="number"/>
            <TextInput source="status"  label="order item status" type="number"/>
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)