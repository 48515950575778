import React, { useEffect, useState } from 'react';
import { Typography, Grid, Paper, Stack, Button } from '@mui/material';
import IconifyIcon from './IconifyIcon';
import SaleCard from './SaleCard';
import { getSalesData } from './sales_1';

const Sales = ( salesData) => {
  const [sales, setSales] = useState(getSalesData(salesData));

  return (
    <Paper sx={{ pt: 2, pb: 2, px: 4, boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.15) 0px 3px 3px' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <div>
          <Typography variant="h5" mb={0.5}>
            Sales Insights
          </Typography>
          {/* <Typography variant="subtitle1" color="primary.lighter">
            Sales Summary
          </Typography> */}
        </div>
        <Button variant="outlined" startIcon={<IconifyIcon icon="solar:upload-linear" />}>
          Export
        </Button>
      </Stack>

      <Grid container spacing={{ xs: 3.875, xl: 2 }} columns={{ xs: 1, sm: 2, md: 4 }}>
      {sales.map((item) => {
        // Debugging log outside of JSX
        // console.log("INSIDE SALES MAP", item);

        // Check if item and item.label are valid before rendering
        if (item && item.label) {
          return (
            <Grid item xs={1} key={item.label}>
              <SaleCard item={item} />
            </Grid>
          );
        }
        // Optionally return null if the item is not valid
        return null;
      })}
    </Grid>
    </Paper>
  );
};

export default Sales;
