import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, Edit, DateTimeInput } from 'react-admin';

export const taxCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="percentage"  label="tax percent" type="string"/>
        </SimpleForm>
    </Create>
);

export const taxShow=()=>(
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="percentage" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        </SimpleShowLayout>
    </Show>
);

export const taxList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="percentage" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};

  export const taxEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="percentage"  label="tax percentage" type="string"/>
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)