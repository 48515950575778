import { useStoreContext, useStore, Link, useDataProvider } from 'react-admin';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useState, useEffect } from 'react';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BottomNavigationCustom from '../common/bottom_nav';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import QRCode from "react-qr-code";
import { createFalse } from 'typescript';

function getTheTax(itemCount) {
    var sum = 0;
    var tax = 0;
    Object.keys(itemCount).map(item => {
        sum += (itemCount[item].count * itemCount[item].price);
        tax = itemCount[item].tax;
    })
    return sum * (tax * 0.01);
}

function getTheTotal(itemCount) {
    var sum = 0;
    Object.keys(itemCount).map(item => {
        sum += (itemCount[item].count * itemCount[item].price);
    })
    return sum + getTheTax(itemCount);
}

function getSummaryComponent(itemCount) {
    return (<BoxedShowLayout>
        <RaBox display="flex" >
            <RaBox display="flex" flexWrap="wrap" flexGrow={1} sx={{
                paddingRight: "50px",
                marginRight: "50px"
            }}>
                <RaBox flex={1} display="flex" flexDirection="column">
                    <Typography variant='h6'>Summary</Typography>
                    <Grid container spacing={2}>
                        {Object.keys(itemCount).map((key) => (
                            <>
                                <Grid item xs={6}>
                                    {key}
                                </Grid>
                                <Grid item xs={6}>
                                    Rs {itemCount[key].count * itemCount[key].price}
                                </Grid>
                            </>
                        ))}
                        <Grid item xs={6}>
                            Tax
                        </Grid>
                        <Grid item xs={6}>
                            Rs {getTheTax(itemCount)}
                        </Grid>
                        <Grid item xs={6}>
                            Total
                        </Grid>
                        <Grid item xs={6}>
                            Rs {getTheTotal(itemCount)}
                        </Grid>
                    </Grid>
                </RaBox>
                <RaBox flex={1}>
                    <Typography variant='h6'>Mode</Typography>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={2}>
                            QR Code
                        </Grid>
                        <Grid item xs={2}>
                            <Checkbox />
                        </Grid>
                        <Grid item xs={8}>
                            <QRCode value="hey" />
                        </Grid> */}
                        <Grid item xs={6}>
                            Cash On Delivery
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox
                                defaultChecked
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained"><Link to="/checkout" sx={{ color: "white" }}>Place Order</Link></Button>
                        </Grid>
                    </Grid>
                </RaBox>
            </RaBox>
        </RaBox>
        <BottomNavigationCustom />
    </BoxedShowLayout >)
}

function getOrderConfirmation(orderUpdateFunc, itemCount) {
    //make the request to complete the order placement

    //clear the store variables to complete the order
    return (<>
        <div>
            "sangam"
        </div>
    </>)
}

const Checkout = () => {
    const store = useStoreContext();
    const [itemCount, setItemCount] = useState(store.getItem('item.count.keeper'));
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    return (!isLoading ? (orderPlaced ? getSummaryComponent(itemCount) : getOrderConfirmation(setOrderPlaced, itemCount)) : null)
}
export default Checkout;