import React, { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";

// Styled components
const StyledCard = styled(Card)(({ theme, status }) => ({
  background: status ? "#4caf50" : "#fff",
  color: status ? "white" : "inherit",
  transition: "0.3s",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
  },
}));

const CreateButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    paddingTop: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const VendorTable = () => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newTable, setNewTable] = useState({
    table_no: "",
    meta: "",
    status: false,
  });
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [currentClickedTableCard, setCurrentClickedTableCard] = useState(null);

  const handleCreateTable = () => {
    setUpdateEnabled(false);
    setCurrentClickedTableCard({ table_no: "", meta: "", status: false });
    setOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewTable((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setNewTable((prev) => ({ ...prev, status: checked }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createOrUpdateTable = () => {
    setLoading(true);
    setOpen(false);
    setLoading(false);
  };

  const handleUpdateDialog = (table) => {
    setUpdateEnabled(true);
    setCurrentClickedTableCard(table);
    setNewTable(table);
    setOpen(true);
  };

  useEffect(() => {
    setLoading(true);
    // Replace with your actual data fetching logic
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList("vendor_table", {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "id", order: "ASC" },
          filter: {},
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [dataProvider]);

  if (loading) return <Loading />;

  return (
    <div>
      <CreateButton
        variant="contained"
        color="primary"
        sx={{ marginTop: "20px" }}
        onClick={handleCreateTable}
      >
        Create New Table
      </CreateButton>
      <Grid container spacing={2}>
        {data.map((table) => (
          <Grid key={table.id} item xs={12} sm={6} md={4} lg={3}>
            <StyledCard
              status={table.status}
              onClick={() => handleUpdateDialog(table)}
            >
              <CardContent>
                <Typography variant="h6">Table ID: {table.id}</Typography>
                <Typography color="text.secondary">
                  Table Number: {table.table_no}
                </Typography>
                <Typography color="text.secondary">
                  Meta: {table.meta != null ? JSON.stringify(table.meta) : null}
                </Typography>
                <Typography id="createdAt" color="text.secondary">
                  Created At: {new Date(table.created_at).toLocaleDateString()}
                </Typography>
                <Typography id="vendorId" color="text.secondary">
                  Vendor ID: {table.vendor_id}
                </Typography>
                <Typography color="text.secondary">
                  Status: {table.status ? "Active" : "Inactive"}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <CustomDialog open={open} onClose={handleClose}>
        <DialogTitle>
          {updateEnabled ? "Update Table" : "Create New Table"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="table_no"
            label="Table Number"
            type="text"
            fullWidth
            name="table_no"
            value={newTable.table_no}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            id="meta"
            label="Meta Information"
            type="text"
            fullWidth
            name="meta"
            value={newTable.meta}
            onChange={handleInputChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newTable.status}
                onChange={handleCheckboxChange}
                name="status"
                color="primary"
              />
            }
            label="Active Status"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={createOrUpdateTable} color="primary">
            {updateEnabled ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default VendorTable;
