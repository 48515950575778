import { RichTextInput } from 'ra-input-rich-text';
import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, DateTimeInput, Edit } from 'react-admin';

export const categoryCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name"  label="category name" type="string"/>
            <RichTextInput source="category_desc_html"  label="category desc"/>
            <TextInput source="parent_id"  label="parent category id" type="number"/>
        </SimpleForm>
    </Create>
);

export const categoryShow=()=>(
    <Show>
        <SimpleShowLayout>
            <TextField source="name" />
            <RichTextField source="category_desc_html" />
            <TextField source="parent_id" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </SimpleShowLayout>
    </Show>
);

export const categoryList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <RichTextField source="category_desc_html" />
        <TextField source="parent_id" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};

  export const categoryEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name"  label="category name" type="string"/>
            <RichTextInput source="category_desc_html"  label="category desc"/>
            <TextInput source="parent_id"  label="parent category id" type="number"/>
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)