import React from 'react';
import '../css/Profile.css';
import BottomNavigationCustom from "../common/bottom_nav";
import avatarImage from '../../images/avatar-1968236_1280.png';

const Profile = () => {
  
  const parseJSON = (key, defaultValue) => {
    const value = localStorage.getItem(key);
    try {
      return value ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error('Parsing error on', key, value);
      return defaultValue;
    }
  };

  
  const empId = localStorage.getItem("empId") || '';
  const name = localStorage.getItem("name") || '';
  const last_name = localStorage.getItem('last_name') || '';  
  const communication_meta = parseJSON('communication_meta', { email: { id: '' }, mobile: { number: '' } });
  const username = localStorage.getItem('username') || '';  
  const created_at = localStorage.getItem('created_at') || new Date().toISOString();  
  const date = new Date(created_at);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  return (
    <>
    <div className="profile-card">
      <div className="profile-header">
        <img src={avatarImage} alt="Profile Avatar" className="profile-avatar" />
        <h2>{`${name} ${last_name}`.trim() || 'No Name'}</h2>
        <p className='ptagheader'>{username || 'No Username'}</p>
      </div>
      <div className="profile-info">
        <div className="info-section">
          <h3><i className='bx bxs-id-card' style={{color: '#df773e'}}></i> Communication</h3>
          <p className='ptag'><i class='bx bxs-envelope'></i> Email : {communication_meta.email.id || 'No Email'}</p> 
          <p className='ptag'><i class='bx bxs-phone-call'></i> Phone : {communication_meta.mobile.number || 'No Phone'}</p>
        </div>
        <div className="projects-section">
          <h3><i class='bx bxs-id-card' style={{color:'#df773e'}}  ></i> Information</h3>
          <p className='ptag'><i class='bx bxs-calendar'></i> Joined : {formattedDate}</p>
          <p className='ptag'><i class='bx bx-user-plus' style={{color:'#fdf8f8' }} ></i> Employee ID : {empId}</p>
        </div>
      </div>
      
    </div>
    </>
  );
};

export default Profile;
