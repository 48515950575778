import React, { useEffect, useState } from "react";
import { Menu } from "react-admin";
import { MenuItemLink } from "react-admin";
import { useTranslate } from "react-admin";
import ListItemIcon from "@mui/material/ListItemIcon";
import itemIcon from "../images/item.png";
import kitchenIcon from "../images/Kitchen.png";
import orderIcon from "../images/Order.png";
import placeOrderIcon from "../images/PlaceOrder.png";
import tableIcon from "../images/table.png";
import tagGroup from "../images/TagGroup.png";
import tax from "../images/Tax.png";
import vendorIcon from "../images/vendor.png";
import userIcon from "../images/User.png";
import orderItemIcon from "../images/orderItem.png";
import dashboardIcon from "../images/dashboard.png";
import categoryIcon from "../images/category.png";
import { authProvider } from "./authprovider";

export const MyMenu = () => {
  const translate = useTranslate();
  const [activeTab, setActiveTab] = useState("");

  const handleMenuItemClick = (path) => {
    setActiveTab(path);
  };

  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const getPermissions = async () => {
      setPermissions(await authProvider.getPermissions());
    };
    getPermissions();
  }, []);

  authProvider.getPermissions();

  const menuItemStyle = (path) => ({
    backgroundColor: activeTab === path ? "#7d7a79" : "transparent",
    "&:hover": {
      backgroundColor: "#66deb6",
    },
    transition: "background-color 0.5s ease",
  });

  return (
    <Menu>
      <MenuItemLink
        to="/"
        primaryText={translate("ra.page.dashboard")}
        leftIcon={
          <img
            src={dashboardIcon}
            alt="Dashboard"
            style={{ width: 24, height: 24 }}
          />
        }
        onClick={() => {
          handleMenuItemClick("/");
        }}
        sx={menuItemStyle("/")}
      />
      {permissions != null && permissions.resources.indexOf("items") > -1 ? (
        <Menu.Item
          to="/items"
          primaryText="Items"
          leftIcon={
            <ListItemIcon>
              <img
                src={itemIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/items")}
          sx={menuItemStyle("/items")}
        />
      ) : null}
      {permissions != null &&
      permissions.resources.indexOf("item_descriptions") > -1 ? (
        <MenuItemLink
          to="/item_descriptions"
          primaryText="Item Descriptions"
          leftIcon={
            <ListItemIcon>
              <img
                src={itemIcon}
                alt="Item Descriptions"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/item_descriptions")}
          sx={menuItemStyle("/item_descriptions")}
        />
      ) : null}

      {permissions != null && permissions.resources.indexOf("category") > -1 ? (
        <Menu.Item
          to="/category"
          primaryText="Category"
          leftIcon={
            <ListItemIcon>
              <img
                src={categoryIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/category")}
          sx={menuItemStyle("/category")}
        />
      ) : null}
      {permissions != null && permissions.resources.indexOf("tag") > -1 ? (
        <MenuItemLink
          to="/tag"
          primaryText="Tag"
          leftIcon={
            <ListItemIcon>
              <img src={tagGroup} alt="Tag" style={{ width: 26, height: 26 }} />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/tag")}
          sx={menuItemStyle("/tag")}
        />
      ) : null}

      {permissions != null &&
      permissions.resources.indexOf("tag_group") > -1 ? (
        <Menu.Item
          to="/tag_group"
          primaryText="Tag Group"
          leftIcon={
            <ListItemIcon>
              <img
                src={tagGroup}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/tag_group")}
          sx={menuItemStyle("/tag_group")}
        />
      ) : null}
      {permissions != null && permissions.resources.indexOf("tax") > -1 ? (
        <Menu.Item
          to="/tax"
          primaryText="Tax"
          leftIcon={
            <ListItemIcon>
              <img src={tax} alt="Items" style={{ width: 26, height: 26 }} />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/tax")}
          sx={menuItemStyle("/tax")}
        />
      ) : null}

      {permissions != null &&
      permissions.resources.indexOf("tax_group") > -1 ? (
        <MenuItemLink
          to="/tax_group"
          primaryText="Tax Group"
          leftIcon={
            <ListItemIcon>
              <img src={tax} alt="Tax" style={{ width: 26, height: 26 }} />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/tax_group")}
          sx={menuItemStyle("/tax_group")}
        />
      ) : null}

      {permissions != null && permissions.resources.indexOf("order") > -1 ? (
        <Menu.Item
          to="/order"
          primaryText="Order"
          leftIcon={
            <ListItemIcon>
              <img
                src={orderIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/order")}
          sx={menuItemStyle("/order")}
        />
      ) : null}
      {permissions != null &&
      permissions.resources.indexOf("order_item") > -1 ? (
        <Menu.Item
          to="/order_item"
          primaryText="Order Items"
          leftIcon={
            <ListItemIcon>
              <img
                src={orderItemIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/order_item")}
          sx={menuItemStyle("/order_item")}
        />
      ) : null}

      {permissions != null && permissions.resources.indexOf("vendors") > -1 ? (
        <Menu.Item
          to="/vendors"
          primaryText="Vendor"
          leftIcon={
            <ListItemIcon>
              <img
                src={vendorIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/vendors")}
          sx={menuItemStyle("/vendors")}
        />
      ) : null}
      {permissions != null &&
      permissions.resources.indexOf("item_menu") > -1 ? (
        <Menu.Item
          to="/item_menu"
          primaryText="Place Order"
          leftIcon={
            <ListItemIcon>
              <img
                src={placeOrderIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/item_menu")}
          sx={menuItemStyle("/item_menu")}
        />
      ) : null}
      {permissions != null &&
      permissions.resources.indexOf("vendor_table") > -1 ? (
        <Menu.Item
          to="/vendor_table"
          primaryText="Tables"
          leftIcon={
            <ListItemIcon>
              <img
                src={tableIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/vendor_table")}
          sx={menuItemStyle("/vendor_table")}
        />
      ) : null}
      {permissions != null &&
      permissions.resources.indexOf("kitchen_view") > -1 ? (
        <Menu.Item
          to="/kitchen_view"
          primaryText="Kitchen"
          leftIcon={
            <ListItemIcon>
              <img
                src={kitchenIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/kitchen_view")}
          sx={menuItemStyle("/kitchen_view")}
        />
      ) : null}
      {permissions != null &&
      permissions.resources.indexOf("User_Access") > -1 ? (
        <Menu.Item
          to="/User_Access"
          primaryText="User"
          leftIcon={
            <ListItemIcon>
              <img
                src={userIcon}
                alt="Items"
                style={{ width: 26, height: 26 }}
              />
            </ListItemIcon>
          }
          onClick={() => handleMenuItemClick("/User_Access")}
          sx={menuItemStyle("/User_Access")}
        />
      ) : null}
    </Menu>
  );
};
