import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, Edit, DateTimeInput, SimpleList } from 'react-admin';
import { useMediaQuery } from '@mui/material';

export const VendorCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="entity_id" label="entity id" type="number" />
            <TextInput source="name" label="vendor name" type="string" />
            <TextInput source="description_id" label="vendor description id" type="number" />
            <TextInput source="config_id" label="vendor config id" type="number" />
            <TextInput source="communication_id" label="vendor conmmunication id" type="number" />
        </SimpleForm>
    </Create>
);

export const VendorShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="entity_id" />
            <TextField source="description_id" />
            <TextField source="config_id" />
            <TextField source="communication_id" />
            <BooleanField source="enabled" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </SimpleShowLayout>
    </Show>
);

export const VendorList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
    return (
        <List>
            {isSmall ? (<SimpleList
                primaryText={record => record.name}
                secondaryText={record => record.enable}
                tertiaryText={record => record.id}
            />) :
                (<Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="entity_id" />
                    <TextField source="name" />
                    <TextField source="description_id" />
                    <TextField source="communication_id" />
                    <TextField source="enable" />
                    <DateField source="created_at" />
                    <DateField source="updated_at" />
                    <TextField source="config_id" />
                    <ShowButton />
                    <EditButton />
                </Datagrid>)
            }
        </List>
    )
};

export const vendorEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="entity_id" label="entity id" type="number" />
            <TextInput source="name" label="vendor name" type="string" />
            <TextInput source="description_id" label="vendor description id" type="number" />
            <TextInput source="config_id" label="vendor config id" type="number" />
            <TextInput source="communication_id" label="vendor conmmunication id" type="number" />
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)