import * as React from 'react';
import { AppBar, Toolbar, IconButton, Typography, MenuItem, ListItemIcon, ListItemText, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSidebarState, UserMenu, useUserMenu, Logout } from 'react-admin';
import logo from '../../images/logo-2-nobg.png';

const SettingsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    return (
        <MenuItem onClick={onClose} ref={ref} {...props}>
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Customize" />
        </MenuItem>
    );
});

export const CustomAppBar = (props) => {
    const [sidebarOpen, setSidebarOpen] = useSidebarState();

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <>
            <CssBaseline />
            <AppBar {...props} position="fixed" sx={{ minHeight:'30px', zIndex: (theme) => theme.zIndex.drawer + 1, background: 'linear-gradient(to right, #ff7e5f, #feb47b)'}}>
                <Toolbar sx={{ minHeight: '20px' }}>  {/* Reduced height */}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleSidebar}
                        sx={{ marginRight: '20px', color: '#1a1a2e' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',height:'30px' }}>
                        <img src={logo} alt="Logo" style={{ height: 40, marginRight: 10 }} />  {/* Adjusted logo height */}
                        <Typography component="div" style={{ fontFamily: 'Roboto, sans-serif', color: '#1a1a2e', fontSize:'30px', fontWeight:'600' }}>
                            KafeNeo
                        </Typography>
                    </div>
                    <UserMenu sx={{ height: '30px', minHeight: '30px'}} icon={
                        <IconButton color="inherit" sx={{ padding: 0, height: '30px', width: '30px', color:'#1a1a2e' }}>
                            <span style={{ color: 'black', fontSize: '20px', lineHeight: '30px' }}>👤</span>
                        </IconButton>
                    }>
                        <Logout />
                    </UserMenu>
                </Toolbar>
            </AppBar>
            <div style={{ minHeight: '20px' }}></div>
        </>
    );
};
