import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title, useStore } from "react-admin";
import Profile from "./custom/profile";
import Analytics from "./custom/Analytics";
import { dataProvider } from "./dataprovider";
import AnalyticsDash from "./custom/AnalyticsDash";

export default () => {
  const [menuData, setMenuData] = useStore("fetch.menu.metadata", null);
  useEffect(() => {
    const pullTheMenuData = async () => {
      dataProvider
        .getMenuDataCustom("menu")
        .then(({ data, tax }) => {
          setMenuData({ data, tax, timestamp: Date.now() });
        })
        .catch((error) => {
          console.error(error);
        });
    };
    pullTheMenuData();
  }, []);
  return (
    <div className="container">
      <Title title="Analytics Dashboard" />
      {/* //<CardContent>This is coming soon</CardContent> */}
      {/* <Profile/> */}
      {/* <Analytics /> */}
      <AnalyticsDash/>
    </div>
  );
};
