import { dataProvider } from "../dataprovider"; 
import React, { useState, useEffect } from "react";
import "../css/kitchenleftview.css";

const ItemAggregationPanel = () => {
  const [itemsToDisplay, setItemsToDisplay] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Assuming dataProvider.getPendingItems() directly returns the array of pendingItems
        const response = await dataProvider.getPendingItems();
        // Update state directly with the response if it's already in the desired format
        setItemsToDisplay(response);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="item-aggregation-panel">
      <h2>Item Summary</h2>
      {itemsToDisplay.map(item => (
        <div className="item-entry" key={item.id}> {/* Use item.id for key to ensure uniqueness */}
          <span className="item-name">{item.name}</span>
          <span className="item-quantity">{item.quantity}</span>
        </div>
      ))}
    </div>
  );
}

export default ItemAggregationPanel;
