import { fetchUtils } from "react-admin";

const apiUrl = "https://vendor-api.kafeneotech.online";
//const apiUrl = "http://localhost:4444";

export const authProvider = {
  login: async ({ username, password }) => {
    if (username.length == 0 || password.length == 0) {
      return Promise.reject();
    }
    var url = `${apiUrl}/users/login`;
    try {
      var { json } = await fetchUtils.fetchJson(url, {
        method: "POST",
        body: JSON.stringify({ username, password }),
      });
      localStorage.setItem("token", json.token);
      localStorage.setItem("id", json.id);
      localStorage.setItem("name", json.name);
      localStorage.setItem("vendor_id", json.vendor_id);
      localStorage.setItem("last_name", json.last_name);
      localStorage.setItem(
        "communication_meta",
        JSON.stringify(json.communication_meta)
      );
      localStorage.setItem("customer_meta", JSON.stringify(json.customer_meta));
      localStorage.setItem("created_at", json.created_at);
      localStorage.setItem("username", json.username);
      localStorage.setItem("empId", json.vendor_emp_id);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("name");
    localStorage.removeItem("vendor_id");
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("name");
      localStorage.removeItem("vendor_id");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    var id = localStorage.getItem("id");
    var name = localStorage.getItem("name");
    if (name && id) {
      return Promise.resolve({
        id: id,
        fullName: name,
      });
    } else {
      return Promise.resolve({
        id: "user",
        fullName: "vendor",
      });
    }
  },
  getPermissions: async () => {
    var token = localStorage.getItem("token");
    var url = `${apiUrl}/users/permission`;
    try {
      var { json } = await fetchUtils.fetchJson(url, {
        method: "GET",
        headers: new Headers({ Authorisation: token }),
      });
      return Promise.resolve(json);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
