const { useStore } = require("react-admin");
const io = require("socket.io-client");
const {
  orderCreationHandler,
  orderPaymentApprovalHandler,
} = require("../handler/order_created_handler");
var socket = null;
const socketURL = "https://varta.kafeneotech.online";

var socketConnected = false;

function getSocketFunc(serverName, reformConnection) {
  if (reformConnection || !socket) {
    socket = io(socketURL, {
      query: {
        identifierId: serverName,
      },
    });
  }
  return socket;
}

function emitMessageWithCommandFunc(command, messageObject) {
  if (!socket) {
    console.log("socket is null please reform the socket connection");
    return { error: new Error("socket empty"), status: "failure" };
  }
  try {
    socket.timeout(2000).emit(command, messageObject, (err) => {
      console.log(err);
    });
    return { error: null, status: "success" };
  } catch (error) {
    console.log("error in emitting the message", error);
    return { error: new Error(error), status: "failure" };
  }
}

function addBaseEventListenerToSocketFunc(
  isOrderModalOpen,
  setIsOrderModalOpen,
  isOrderModalOpenForManaged,
  setIsOrderModalOpenForManaged
) {
  socket.on("connect", () => {
    console.log("client connected to the server");
    socketConnected = true;
  });

  socket.on("disconnect", (data) => {
    console.log("client disconnected from the server");
    socketConnected = false;
  });

  socket.on("connect_error", (err) => {
    console.log(`connect_error due to ${err.message}`);
  });

  socket.on("custom_server_reconnected", (data) => {
    console.log("Reconnected to the server with socket ID:");
  });

  socket.on("custom_server_connected", (data) => {
    console.log("Connected to the server with new socket ID:");
  });
  socket.on("ORDER_PLACED_CRM_ORDERDATA", async (data) => {
    await orderCreationHandler(data);
    let path = window.location.hash;
    if (path && path != "") {
      path = path.replace("#", "");
    }
    if (path.includes("kitchen_view")) {
      setIsOrderModalOpen(!isOrderModalOpen);
    }
    if (path.includes("orders")) {
      setIsOrderModalOpenForManaged(!isOrderModalOpenForManaged);
    }
  });
  socket.on("ORDER_PLACED_APPROVAL_NEEDED_CRM", async (data) => {
    //add here the handler of the data
    await orderPaymentApprovalHandler(data);
  });
}

function closeSocketFunc() {
  if (socket && socketConnected && socket.readyState === 1) {
    socket.close();
  }
  socket = null;
}

export const getSocket = getSocketFunc;
export const emitMessageWithCommand = emitMessageWithCommandFunc;
export const addBaseEventListenerToSocket = addBaseEventListenerToSocketFunc;
export const closeSocket = closeSocketFunc;
