import {
  Admin,
  Resource,
  ListGuesser,
  usePermissions,
  EditGuesser,
  Create,
  ShowGuesser,
  CustomRoutes,
  Menu,
  Layout,
} from "react-admin";
import { dataProvider } from "./dataprovider";
import { authProvider } from "./authprovider";
import Dashboard from "./dashboard";
import { VendorCreate, vendorEdit, VendorList, VendorShow } from "./vendor";
import { itemCreate, itemEdit, itemList } from "./item";
import {
  itemDescriptionCreate,
  itemDescriptionEdit,
  itemDescriptionList,
} from "./item_descriptions";
import { categoryCreate, categoryEdit, categoryList } from "./category";
import { tagCreate, tagEdit, tagList } from "./tag";
import { tagGroupCreate, tagGroupEdit, tagGroupList } from "./tag_group";
import { taxCreate, taxEdit, taxList } from "./tax";
import { taxGroupCreate, taxGroupEdit, taxGroupList } from "./tax_group";
import { orderCreate, orderEdit, orderList, orderShow } from "./order";
import {
  orderItemCreate,
  orderItemEdit,
  orderItemList,
  orderItemShow,
} from "./order_item";
import { Route } from "react-router-dom";
import ItemMenu from "./custom/item_menu";
import { MyLayout } from "./layout";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import DescriptionIcon from "@mui/icons-material/Description";
import TagIcon from "@mui/icons-material/Tag";
import LabelIcon from "@mui/icons-material/Label";
import PersonIcon from "@mui/icons-material/Person";
import {
  VendorEmployeeCreate,
  VendorEmployeeEdit,
  VendorEmployeeList,
  VendorEmployeeShow,
} from "./vendor_employee";
import Order from "./custom/order";
import Profile from "./custom/profile";
import Cart from "./custom/cart";
import Checkout from "./custom/checkout";
import VendorTable from "./custom/vendor_table";
import KitchenView from "./custom/kitchen_view";
import UserAccess from "./custom/user_access";

const App = () => (
  <Admin
    layout={MyLayout}
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
  >
    {(permissions) => (
      <>
        {permissions.resources.indexOf("items") > -1 ? (
          <Resource
            name="items"
            list={itemList}
            edit={itemEdit}
            show={ShowGuesser}
            create={itemCreate}
            icon={InventoryIcon}
          />
        ) : null}
        {permissions.resources.indexOf("item_descriptions") > -1 ? (
          <Resource
            name="item_descriptions"
            options={{ label: "ItemDescriptions" }}
            list={itemDescriptionList}
            edit={itemDescriptionEdit}
            show={ShowGuesser}
            create={itemDescriptionCreate}
            icon={DescriptionIcon}
          />
        ) : null}
        {permissions.resources.indexOf("category") > -1 ? (
          <Resource
            name="category"
            options={{ label: "Category" }}
            list={categoryList}
            edit={categoryEdit}
            show={ShowGuesser}
            create={categoryCreate}
            icon={CategoryIcon}
          />
        ) : null}
        {permissions.resources.indexOf("tag") > -1 ? (
          <Resource
            name="tag"
            options={{ label: "Tag" }}
            list={tagList}
            edit={tagEdit}
            show={ShowGuesser}
            create={tagCreate}
            icon={TagIcon}
          />
        ) : null}
        {permissions.resources.indexOf("tag_group") > -1 ? (
          <Resource
            name="tag_group"
            options={{ label: "Tag Group" }}
            list={tagGroupList}
            edit={tagGroupEdit}
            show={ShowGuesser}
            create={tagGroupCreate}
            icon={LabelIcon}
          />
        ) : null}
        {permissions.resources.indexOf("tax") > -1 ? (
          <Resource
            name="tax"
            options={{ label: "Tax" }}
            list={taxList}
            edit={taxEdit}
            show={ShowGuesser}
            create={taxCreate}
            icon={LabelIcon}
          />
        ) : null}
        {permissions.resources.indexOf("tax_group") > -1 ? (
          <Resource
            name="tax_group"
            options={{ label: "Tax Group" }}
            list={taxGroupList}
            edit={taxGroupEdit}
            show={ShowGuesser}
            create={taxGroupCreate}
            icon={LabelIcon}
          />
        ) : null}
        {permissions.resources.indexOf("order") > -1 ? (
          <Resource
            name="order"
            options={{ label: "Order" }}
            list={orderList}
            edit={orderEdit}
            show={orderShow}
            create={orderCreate}
            icon={LabelIcon}
          />
        ) : null}
        {permissions.resources.indexOf("order_item") > -1 ? (
          <Resource
            name="order_item"
            options={{ label: "Order Item" }}
            list={orderItemList}
            edit={orderItemEdit}
            show={orderItemShow}
            create={orderItemCreate}
            icon={LabelIcon}
          />
        ) : null}
        {permissions.resources.indexOf("vendors") > -1 ? (
          <Resource
            name="vendors"
            list={VendorList}
            edit={vendorEdit}
            show={VendorShow}
            create={VendorCreate}
            icon={LabelIcon}
          />
        ) : null}
        {permissions.resources.indexOf("vendor_employee") > -1 ? (
          <Resource
            name="vendor_employee"
            list={VendorEmployeeList}
            edit={VendorEmployeeEdit}
            show={VendorEmployeeShow}
            create={VendorEmployeeCreate}
            icon={PersonIcon}
          />
        ) : null}
        {permissions.resources.indexOf("item_menu") > -1 ? (
          <CustomRoutes>
            <Route path="/item_menu" element={<ItemMenu />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("orders") > -1 ? (
          <CustomRoutes>
            <Route path="/orders" element={<Order />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("profile") > -1 ? (
          <CustomRoutes>
            <Route path="/profile" element={<Profile />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("cart") > -1 ? (
          <CustomRoutes>
            <Route path="/cart" element={<Cart />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("checkout") > -1 ? (
          <CustomRoutes>
            <Route path="/checkout" element={<Checkout />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("vendor_table") > -1 ? (
          <CustomRoutes>
            <Route path="/vendor_table" element={<VendorTable />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("kitchen_view") > -1 ? (
          <CustomRoutes>
            <Route path="/kitchen_view" element={<KitchenView />} />
          </CustomRoutes>
        ) : null}
        {permissions.resources.indexOf("User_Access") > -1 ? (
          <CustomRoutes>
            <Route path="/User_Access" element={<UserAccess />} />
          </CustomRoutes>
        ) : null}
      </>
    )}
  </Admin>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) => {
        console.log("Service Worker registered is registered");
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

if ("serviceWorker" in navigator && "PushManager" in window) {
  let token: any;
  navigator.serviceWorker.ready
    .then(async (registration) => {
      // get the subscription from the subscription manager
      let currentSubscription =
        await registration.pushManager.getSubscription();
      if (currentSubscription) {
        let unsubscribe = await currentSubscription.unsubscribe();
      }
      // get the token by making api call
      token = await getToken("sangam.sangam009@gmail.com");
      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: token.publicKey, // Replace with your VAPID public key
      });
    })
    .then((subscription) => {
      //make request to send the data to backend for subscription
      let request = {
        identifier: token.identifier,
        subscription: subscription,
      };

      let requestOpt = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // You can set other headers as needed
        },
        body: JSON.stringify(request),
      };
      fetch("https://vendor-api.kafeneotech.online/subscription", requestOpt)
        .then((response) => {
          console.log("response for subscription received");
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.error("Push subscription failed:", error);
    });
}

function getToken(identifier: String) {
  return new Promise((resolve, reject) => {
    fetch(
      "https://vendor-api.kafeneotech.online/getToken?identifier=" + identifier
    )
      .then((response) => {
        let finalResponse = response.json();
        return resolve(finalResponse);
      })
      .catch((error) => {
        console.log("error is " + error);
        return reject(error);
      });
  });
}

export default App;
