import React, { useEffect, useState, useRef } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import { dataProvider } from "../dataprovider";
import QRCode from "qrcode.react";
import "../css/invoice.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";

const InvoiceModal = ({ open, handleClose, number }) => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const printComponentRef = useRef();

  useEffect(() => {
    const fetchBill = async () => {
      setIsLoading(true);
      try {
        const response = await dataProvider.getInvoiceData(number);
        if (response.json.status === "success") {
          setInvoiceData(response.json.response);
        } else {
          console.error("Invoice fetch error:", response.message);
        }
      } catch (error) {
        console.error("Error fetching Invoice Detail:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (number) {
      fetchBill();
    }
  }, [number]);

  const handleDownload = async () => {
    // yet to add new code for download
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: `Invoice_${number}`,
    onAfterPrint: handleClose,
  });

  const calculateTotalAmount = () => {
    if (!invoiceData) return 0;
    const total = invoiceData.order.reduce(
      (acc, curr) => acc + curr.quantity * curr.price,
      0
    );
    const taxAmount = total * (invoiceData.order[0].total_tax_percentage / 100);
    return total + taxAmount;
  };

  // Dynamic UPI
  const upiUrl = `upi://pay?pa=7800686875@paytm&pn=ADITYA GUPTA&mc=0000&tid=tr${new Date().getTime()}&tr=123456789&tn=Payment for Invoice ${number}&am=${calculateTotalAmount()}&cu=INR`;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        className="invoice-POS"
        ref={printComponentRef}
        p={1}
        style={{ backgroundColor: "white" }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          invoiceData && (
            <>
              <center id="top">
                <div className="info">
                  <h3>{invoiceData.config[0].config_payload.name}</h3>
                </div>
              </center>
              <div id="mid">
                <div className="info">
                  <p>
                    GSTIN: {invoiceData.config[0].config_payload.GST}
                    <br />
                    FSSAI No: {invoiceData.config[0].config_payload.fsai_no}
                    <br />
                    Address: {invoiceData.config[0].config_payload.address}
                    <br />
                    Email: {invoiceData.config[0].config_payload.email}
                    <br />
                    Phone: {invoiceData.config[0].config_payload.phone}
                    <br />
                    Order Time: {invoiceData.order[0].created_at}
                    <br />
                    Table No: {invoiceData.order[0].table_no}
                    <br />
                    OrderId: {invoiceData.order[0].order_id}
                    <br />
                  </p>
                </div>
              </div>

              <div id="bot">
                <div id="table">
                  <table>
                    <tr className="tabletitle">
                      <td className="tax-head">
                        <h4>Item</h4>
                      </td>
                      <td className="tax-head">
                        <h4>Qty</h4>
                      </td>
                      <td className="tax-head">
                        <h4>Price</h4>
                      </td>
                      <td className="tax-head">
                        <h4>Sub Total</h4>
                      </td>
                    </tr>
                    {invoiceData.order.map((item, index) => (
                      <tr className="service" key={index}>
                        <td className="tableitem">
                          <p className="itemtext">{item.item_name}</p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">{item.quantity}</p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">{item.price}</p>
                        </td>
                        <td className="tableitem">
                          <p className="itemtext">
                            {(item.quantity * item.price).toFixed(2)}
                          </p>
                        </td>
                      </tr>
                    ))}
                    <tr className="tabletitle">
                      <td></td>
                      <td className="tax-head">SGST</td>
                      <td className="tax-head">
                        <p>
                          {(
                            invoiceData.order[0].total_tax_percentage / 2
                          ).toFixed(2)}
                          %
                        </p>
                      </td>
                      <td className="tax-head">
                        <p>
                          ₹
                          {(
                            invoiceData.order.reduce(
                              (acc, curr) => acc + curr.price * curr.quantity,
                              0
                            ) *
                            (invoiceData.order[0].total_tax_percentage / 200)
                          ).toFixed(2)}
                        </p>
                      </td>
                    </tr>
                    <tr className="tabletitle">
                      <td></td>
                      <td className="tax-head">CGST</td>
                      <td className="tax-head">
                        <p>
                          {(
                            invoiceData.order[0].total_tax_percentage / 2
                          ).toFixed(2)}
                          %
                        </p>
                      </td>
                      <td className="tax-head">
                        <p>
                          ₹
                          {(
                            invoiceData.order.reduce(
                              (acc, curr) => acc + curr.price * curr.quantity,
                              0
                            ) *
                            (invoiceData.order[0].total_tax_percentage / 200)
                          ).toFixed(2)}
                        </p>
                      </td>
                    </tr>
                    <tr className="tabletitle">
                      <td></td>
                      <td className="tax-total" colSpan="2">
                        <h4>Total</h4>
                      </td>
                      <td className="tax-total">
                        <h4>
                          ₹
                          {(
                            invoiceData.order.reduce(
                              (acc, curr) => acc + curr.price * curr.quantity,
                              0
                            ) *
                            (1 +
                              invoiceData.order[0].total_tax_percentage / 100)
                          ).toFixed(2)}
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  className="qr"
                  style={{ textAlign: "center", margin: "10px 0" }}
                >
                  <Typography variant="body1" align="center">
                    Use QR to pay Bill
                  </Typography>
                  <QRCode value={upiUrl} size={130} level="H" />
                </div>
                <div id="legalcopy">
                  <p className="legal">
                    <strong>|| Thank you for your Order || </strong>
                  </p>
                  <p className="legal">
                    <strong>|| Powered By KafeNeoTech ||</strong>
                  </p>
                </div>
              </div>
            </>
          )
        )}
        {!isLoading && (
          <center>
            <Button onClick={handleDownload} sx={{ mt: 2, mr: 1 }}>
              Download
            </Button>
            <Button onClick={handlePrint} sx={{ mt: 2 }}>
              Print
            </Button>
          </center>
        )}
      </Box>
    </Modal>
  );
};

export default InvoiceModal;
