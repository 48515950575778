import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, Edit, DateTimeInput, BooleanInput } from 'react-admin';

export const orderCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="created_by_vendor"  label="item id" type="number"/>
            <TextInput source="create_by_user"  label="quantity" type="number"/>
            <TextInput source="table_no"  label="order id" type="number"/>
            <TextInput source="status"  label="order item status" type="number"/>
        </SimpleForm>
    </Create>
);

export const orderShow=()=>(
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="status" />
            <TextField source="created_by_vendor" />
            <TextField source="create_by_user" />
            <TextField source="table_no" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        </SimpleShowLayout>
    </Show>
);

export const orderList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="status" />
            <TextField source="created_by_vendor" />
            <TextField source="create_by_user" />
            <TextField source="table_no" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};

  export const orderEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="created_by_vendor"  label="item id" type="number"/>
            <TextInput source="create_by_user"  label="quantity" type="number"/>
            <TextInput source="table_no"  label="order id" type="number"/>
            <TextInput source="status"  label="order item status" type="number"/>
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)