import React, {useState,useEffect}from 'react';
import { useLocation } from 'react-router-dom'; 
import { useStoreContext } from 'react-admin';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Link } from 'react-router-dom';


const BottomNavigationCustom = () => {
    const store = useStoreContext();
    const cartCount = store.getItem('cart.total.number') || 0;
    const location = useLocation(); 

   
    const getCurrentTab = () => {
        switch (location.pathname) {
            case '/item_menu':
                return 0;
            case '/cart':
                return 1;
            case '/orders':
                return 2;
            default:
                return false;
        }
    };

    const [value, setValue] = useState(getCurrentTab());

    useEffect(() => {
        
        setValue(getCurrentTab());
    }, [location]); 

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            showLabels
            sx={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                zIndex: 1000,
                left: 0,
                right: 0,
                maxWidth: '100vw',
                '& .MuiBottomNavigationAction-root': {
                    '@media (max-width:600px)': {
                        minWidth: 'auto',
                        padding: '6px 0',
                    },
                },
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                },
                height: 'auto',
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'center',
            }}
        >
            <BottomNavigationAction component={Link} to="/item_menu" label="Menu" icon={<MenuIcon />} />
            <BottomNavigationAction component={Link} to="/cart" label="Cart" icon={<Badge badgeContent={cartCount} color="primary"><AddShoppingCartIcon /></Badge>} />
            <BottomNavigationAction component={Link} to="/orders" label="Orders" icon={<ViewListIcon />} />
        </BottomNavigation>
    );
};

export default BottomNavigationCustom;
