import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CustomerSatisfactionChart = () => {
  const [noData, setNoData] = useState(false);

  const options = {
    chart: {
      type: 'pie',
      height: 300,
      toolbar: {
        show: false,
      },
    },
    title: {
      text: 'Customer Satisfaction Level',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3a27c4',
      },
    },
    labels: ['1 (Bad)', '2', '3', '4', '5 (Excellent)'],
    colors: ['#f44336', '#ff9800', '#ffeb3b', '#4caf50', '#2196f3'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
            labels: {
              colors: '#666',
              fontSize: '10px',
            },
          },
        },
      },
    ],
    tooltip: {
      theme: 'light',
    },
    noData: {
      text: 'No Data Available, Take a feedback from Customer',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: '#3a27c4',
        fontSize: '14px',
      },
    },
  };

  const series = noData ? [] : [10, 5, 15, 20, 25]; // Example data

  return (
    <div style={styles.chartContainer}>
      <button style={styles.button} onClick={() => setNoData(!noData)}>
        Toggle Data
      </button>
      <ReactApexChart options={options} series={series} type="pie" height={270} />
    </div>
  );
};

const styles = {
  chartContainer: {
    backgroundColor: '#FFFFFF',
    padding: '2px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Roboto, sans-serif',
    width: '100%',
    height: '317px',  // Fixed height to maintain the same size when toggling data
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center align the content vertically
  },
  button: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '5px',
    backgroundColor: '#3a27c4',
    color: '#FFFFFF',
    border: 'none',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
};

export default CustomerSatisfactionChart;
