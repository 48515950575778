export interface DayData {
    totalOrders: number;
    completedOrders: number;
    totalSales: number;
    pendingOrders: number;
  }
  
export interface SalesData {
  currentDay: DayData;
  lastDay: DayData;
}

export const calculateGrowth = (current: any, last: any) => {
  if (last === 0) return '0%';
  const growth = ((current - last) / last) * 100;
  return `${growth.toFixed(2)}%`;
};
  