import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, Edit, DateTimeInput } from 'react-admin';

export const tagGroupCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="tag_ids"  label="tag ids" type="string"/>
        </SimpleForm>
    </Create>
);

export const tagGroupShow=()=>(
    <Show>
        <SimpleShowLayout>
        <TextField source="name" />
            <TextField source="id" />
            <TextField source="tag_ids" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        </SimpleShowLayout>
    </Show>
);

export const tagGroupList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="tag_ids" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};

  export const tagGroupEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="tag_ids"  label="tag ids" type="string"/>
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)