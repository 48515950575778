import moment from "moment";

export const Utils = {
    dateFormater:(value: any)=>{
        if(value == null || value == '') return '';
        if(typeof value != 'object') value = new Date(value);
        return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    getTimeAgo :(timeStamp: string): string => {
        const currentTimestamp = new Date(new Date().getTime() + (330 * 60000));
        const previousTimestamp = new Date(timeStamp);
        const timeDifference = currentTimestamp.getTime() - previousTimestamp.getTime();
        
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        
        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }
    },
    formatDate : (dateString: string): string => {
        const options: Intl.DateTimeFormatOptions = { 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false, // Use 24-hour format
            timeZone: 'Asia/Kolkata' // Ensure the time is converted to IST
        };
        // Use a locale that typically uses 24-hour time formats, such as 'en-GB' or keep 'en-US' if preferred
        return new Date(dateString).toLocaleTimeString('en-GB', options);
    },
}