import {
    Typography,
    IconButton,
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import { useStore } from "react-admin";

const MenuItemAccordian = ({ item, setIsLoading, isLoading }) => {
    const [itemLevelCount, setItemLevelCount] = useStore('item.count.keeper', {})
    const [cartCount, setCartCount] = useStore('cart.total.number', 0);

    const handleAddToCart = (item) => {
        setIsLoading(true)
        setCartCount(cartCount + 1)
        const currentCount = itemLevelCount[item.name]?.count || 0;
        const newItem = { ...item, count: currentCount + 1 };
        const newCount = { ...itemLevelCount, [item.name]: newItem };
        setItemLevelCount(newCount);
        setIsLoading(false)
    }

    const handleRemoveFromCart = (item) => {
        setIsLoading(true)
        const currentCount = itemLevelCount[item.name]?.count || 0;
        if (cartCount > 0) {
            setCartCount(cartCount - 1)
        }
        if (currentCount > 0) {
            let newCountValue = Math.max(currentCount - 1, 0)
            //remove the item with zero value
            if (newCountValue == 0) {
                let itemLevelCopy = { ...itemLevelCount }
                delete itemLevelCopy[item.name]
                setItemLevelCount(itemLevelCopy);
                setIsLoading(false)
                return
            }
            const newItem = { ...item, count: newCountValue };
            const newCount = { ...itemLevelCount, [item.name]: newItem };
            setItemLevelCount(newCount);
        }
        setIsLoading(false)
    };
    return (
        <ListItem key={item.id} divider sx={{ backgroundColor: "#ECEFF1", color: '#37474F' }}>
            <ListItemAvatar>
                <Avatar src={item.description_image} />
            </ListItemAvatar>
            <ListItemText primary={item.name} secondary={`Price: ₹${item.price}`} />
            <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton sx={{ color: 'red' }} edge="end" aria-label="remove" onClick={() => handleRemoveFromCart(item)}>
                    <RemoveIcon />
                </IconButton>
                <Typography sx={{ width: '40px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="body1">
                    {itemLevelCount[item.name]?.count ?? 0}
                </Typography>
                <IconButton sx={{ color: 'green' }} edge="end" aria-label="add" onClick={() => handleAddToCart(item)}>
                    <AddIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}
export default MenuItemAccordian;