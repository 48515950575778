import React from 'react';
import { Paper, Typography, Stack, Divider } from '@mui/material';
import CustomerSatisfactionChart from './CustomerSatisfactionChart';

const CustomerSatisfaction = () => {
  return (
    <Paper sx={{ py: 0.5, px: 1 ,mt:0.5,boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.15) 0px 3px 3px'
    }}>
      {/* <Typography variant="h6" color="primary.dark" mb={1} align="center">
        Customer Satisfaction
      </Typography> */}
      <CustomerSatisfactionChart />
    </Paper>
  );
};

export default CustomerSatisfaction;
