import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useStoreContext } from "react-admin";
import { handleOrderApproval } from "../../handler/order_created_handler";

const UnifiedOrderNotificationCenter = () => {
  const store = useStoreContext();
  const placedOrders = store.getItem("recent.order.placed") || [];
  const approvalOrders = store.getItem("pending.approval.order") || [];

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isApproval, setIsApproval] = useState(false);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCardClick = (order, isApproval) => {
    setSelectedOrder(order);
    setIsApproval(isApproval);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedOrder(null);
  };

  const callToSendEventToCustomerApp = (payload, order_id, status) => {
    fetch(
      "https://vendor-api.kafeneotech.online/api/varta/order_status",
      payload
    )
      .then((response) => response.json())
      .then((data) => {
        handleOrderApproval(order_id, status);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirm = () => {
    const token = localStorage.getItem("token");
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: selectedOrder.user_id,
        vendor_id: localStorage.getItem("vendor_id"),
        amount: selectedOrder.amount,
        order_id: selectedOrder.orderId,
        status: "accepted",
      }),
    };
    callToSendEventToCustomerApp(payload, selectedOrder.orderId, "accepted");
    handleClose();
  };

  const handleReject = () => {
    const token = localStorage.getItem("token");
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: selectedOrder.user_id,
        vendor_id: localStorage.getItem("vendor_id"),
        amount: selectedOrder.amount,
        order_id: selectedOrder.orderId,
        status: "reject",
      }),
    };
    callToSendEventToCustomerApp(payload, selectedOrder.orderId, "reject");
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "auto",
        height: "100%",
        maxHeight: 404,
        overflowY: "auto",
        // border: "1px solid grey",
        borderRadius: '5px',
        padding: '5px',
        position: "relative",
        backgroundColor: '#f5f6f7',
        //boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.15) 0px 3px 3px',
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "#3f51b5",
          zIndex: 1,
          padding: 1,
          borderBottom: "1px solid grey",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h6" sx={{ color: "white", fontSize: "15px" }}>
          Order Notifications
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {placedOrders.map((order) => (
          <Grid item xs={12} key={order.orderId}>
            <Card
              sx={{
                backgroundColor: "#e3f2fd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleCardClick(order, false)}
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ color: "#1a237e" }}>
                  #{order.orderId}
                </Typography>
                <Typography variant="body2" sx={{ color: "#1a237e" }}>
                  {formatDate(order.date)}
                </Typography>
                {order.itemIds.map((itemId) => (
                  <Typography
                    key={itemId}
                    variant="body2"
                    sx={{ color: "#1a237e", marginTop: 1 }}
                  >
                    {order[itemId].name}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
        {approvalOrders.map((order) => (
          <Grid item xs={12} key={order.orderId}>
            <Card
              sx={{
                backgroundColor: "#ffe0b2",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleCardClick(order, true)}
            >
              <CardContent>
                <Typography variant="subtitle1" sx={{ color: "#e65100" }}>
                  #{order.orderId}
                </Typography>
                <Typography variant="body2" sx={{ color: "#e65100" }}>
                  {formatDate(order.date)}
                </Typography>
                {order.itemIds.map((itemId) => (
                  <Typography
                    key={itemId}
                    variant="body2"
                    sx={{ color: "#e65100", marginTop: 1 }}
                  >
                    {order[itemId].name}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={isModalOpen} onClose={handleClose}>
        <DialogTitle>{isApproval ? "Approve Payment" : "Order Details"}</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <Typography>Order ID: #{selectedOrder.orderId}</Typography>
              <Typography>Date: {formatDate(selectedOrder.date)}</Typography>
              {selectedOrder.itemIds.map((itemId) => (
                <Typography key={itemId}>
                  {selectedOrder[itemId].name}
                </Typography>
              ))}
            </>
          )}
        </DialogContent>
        {isApproval && (
          <DialogActions>
            <Button onClick={handleReject} color="secondary">
              Reject
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export default UnifiedOrderNotificationCenter;
