import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = "https://vendor-api.kafeneotech.online";
//const apiUrl = "http://localhost:4444";

const httpClient = fetchUtils.fetchJson;

export const dataProvider = {
  getList: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, { headers }).then(({ headers, json }) => ({
      data: json.response,
      total: json.total,
    }));
  },

  getOne: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    return httpClient(`${apiUrl}/${resource}/${params.id}`, { headers }).then(
      ({ json }) => json
    );
  },

  getMany: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, { headers }).then(({ json }) => ({
      data: json.response,
    }));
  },

  getOrdersByVendorId: async (resource, page, limit) => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });

      const url = `${apiUrl}/${resource}/${vendorId}?page=${page}&limit=${limit}`;
      const response = await httpClient(url, { headers });

      return response;
    } catch (error) {
      console.error("Error fetching vendor employee by id:", error);
      throw error;
    }
  },
  getVendorEmployee: async (resource) => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const userId = localStorage.getItem("id");
      const headers = new Headers({ Authorisation: token });

      const url = `${apiUrl}/${resource}/${userId}`;

      const response = await httpClient(url, { headers });
      return response;
    } catch (error) {
      console.error("Error fetching orders by vendor ID:", error);
      throw error;
    }
  },

  getManyReference: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, { headers }).then(({ headers, json }) => ({
      data: json,
      total: json.total,
    }));
  },

  update: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    if (resource == "items") {
      localStorage.removeItem("RaStore.fetch.menu.metadata");
    }
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers,
    }).then(({ json }) => ({ data: json.data }));
  },

  updateMany: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    if (resource == "items") {
      localStorage.removeItem("RaStore.fetch.menu.metadata");
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers,
    }).then(({ json }) => ({ data: json }));
  },
  updateStatus: async (orderId, newStatus, table, customerId) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({
      Authorisation: token,
      "Content-Type": "application/json",
    });
    const response = await fetch(`${apiUrl}/orders/${orderId}`, {
      method: "PATCH",
      headers,
      body: JSON.stringify({
        OrderStatus: newStatus,
        table,
        orderId,
        customerId,
      }),
    });
    return response;
  },

  create: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers,
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      headers,
    }).then(({ json }) => ({ data: json.data }));
  },
  getEmployeeInfo: async (params) => {
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({ Authorisation: token });
      const url = `${apiUrl}/vendor_employee/vendor/${params}`;
      const response = await httpClient(url, { headers });
      return response.json.data;
    } catch (error) {
      console.log("Error fetching Employee Info");
      throw error;
    }
  },
  getCountsOfOrders: async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({ Authorisation: token });
      const url = `${apiUrl}/api/v1/count/cporder`;
      const response = await httpClient(url, { headers });
      return response.json.counts;
    } catch (error) {
      console.log("Error in getting counts of orders");
      throw error;
    }
  },
  getMenuDataCustom: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/${resource}`;

    return httpClient(url, { headers }).then(({ headers, json }) => ({
      data: json.response,
      tax: json.tax,
      total: json.total,
    }));
  },
  getVendorTableDataCustom: (resource, params = {}) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const vendor_id = localStorage.getItem("vendor_id") || null;
    const url = `${apiUrl}/${resource}/${vendor_id}`;

    return httpClient(url, { headers }).then(({ headers, json }) => ({
      data: json.data,
    }));
  },
  setVendorDataCustom: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/${resource}`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    }).then(({ json }) => ({ data: json }));
  },
  placeOrder: (resource, params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/${resource}`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers,
    }).then(({ json }) => {
      return { data: { ...params.data, id: json.data.id } };
    });
  },
  searchOrders: async (orderId, tableNo, page) => {
    try {
      const limit = 10;
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      let url;
      if (orderId) {
        url = `${apiUrl}/api/v1/order/search?orderId=${orderId}&vendorId=${vendorId}&page=${page}&limit=${limit}`;
      } else if (tableNo) {
        url = `${apiUrl}/api/v1/order/search?tableNo=${tableNo}&vendorId=${vendorId}&page=${page}&limit=${limit}`;
      } else {
        throw new Error("Please provide either orderId or tableNo");
      }
      const response = await httpClient(url, { headers });
      return response.json.orders;
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  },
  getPendingOrder: async () => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      let url = `${apiUrl}/api/kitchen_view/getPendingOrders/${vendorId}`;
      const response = await httpClient(url, { headers });
      return response.json.orders;
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  },
  getPendingItems: async () => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      let url = `${apiUrl}/api/kitchen_view/getpendingItems/${vendorId}`;
      const response = await httpClient(url, { headers });
      return response.json.pendingItems;
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  },
  generalUpdateStatus: async (orders, id, newStatus, type) => {
    try {
      // Handling changes for customer orders
      if (type === "customer_order") {
        const resp = await dataProvider.updateStatus(
          id,
          newStatus,
          "customer_order",
          id
        );
        if (resp.ok) {
          // Update the local state for the order status
          const updatedOrders = orders.map((order) => {
            if (order.id === id) {
              // Additionally update all item statuses within the order if necessary
              const updatedOrderItems = order.order_item.map((item) => {
                dataProvider.updateStatus(
                  item.id,
                  newStatus,
                  "order_item",
                  item.id
                );

                return { ...item, status: newStatus };
              });

              return {
                ...order,
                status: newStatus,
                order_item: updatedOrderItems,
              };
            }
            return order;
          });

          return updatedOrders;
        }
      }
      // Handling changes for individual order items
      else if (type === "order_item") {
        const resp = await dataProvider.updateStatus(
          id,
          newStatus,
          "order_item",
          id
        );
        if (resp.ok) {
          // Create a new orders array with the updated item status
          const updatedOrders = orders.map((order) => ({
            ...order,
            order_item: order.order_item.map((item) =>
              item.id === id ? { ...item, status: newStatus } : item
            ),
          }));
          return updatedOrders;
        }
      }

      // Optionally, refresh orders from the server
      // fetchOrders(currentPage);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  },

  getInvoiceData: async (orderId) => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      let url = `${apiUrl}/api/biller/print/${orderId}`;
      const response = await httpClient(url, { headers });
      return response;
    } catch (error) {
      console.error("Error fetching Bill Invoice:", error);
      throw error;
    }
  },

  fetchRoles: async () => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      let url = `${apiUrl}/users/roles/`;
      const response = await httpClient(url, { headers });
      return response.json.roles;
    } catch (error) {
      console.log("Error fetching Roles");
      throw error;
    }
  },
  createUserEmployee: (params) => {
    const token = localStorage.getItem("token");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/users/create_user`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    }).catch((error) => {
      console.log("Error while creating user");
      throw error;
    });
  },

  vendorEmployees: async () => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      let url = `${apiUrl}/vendor_employee/vendorId/${vendorId}`;
      const response = await httpClient(url, { headers });
      return response.json.response;
    } catch (error) {
      console.log("Error fetching Vendor employees details");
      throw error;
    }
  },
  filteredStatusOrders: async (page, limit, status) => {
    try {
      const token = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");
      const headers = new Headers({ Authorisation: token });
      const url = `${apiUrl}/api/v1/order/status/?status=${status}&page=${page}&limit=${limit}`;
      const response = await httpClient(url, { headers });
      return response.json.data;
    } catch (error) {
      console.log("Error fetching Vendor employees details");
      throw error;
    }
  },
  fetchAnalyticsData: async (fromDate, toDate) => {
    const token = localStorage.getItem("token");
    const vendorId = localStorage.getItem("vendor_id");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/api/analytics/data?fromDate=${fromDate}&toDate=${toDate}`;
    const response = await httpClient(url, { headers });
    return response.json;
  },
  ImageUpload: async (image) => {
    const formData = new FormData();
    formData.append("image", image);

    try {
      const token = localStorage.getItem("token");
      const headers = new Headers({ Authorisation: token });
      const url =
        "https://vendor-api.kafeneotech.online/utility/menu_update_image";
      const response = await httpClient(url, {
        method: "POST",
        headers,
        body: formData,
      });
      if (response.status == 200) {
        console.log("Image uploaded successfully.");
      } else {
        console.log("Failed to upload image.");
        console.error("Server error:", response.statusText);
      }
    } catch (error) {
      alert("An error occurred while uploading the image.");
      console.error("Error:", error);
    }
  },
  GetSalesData: async () =>{
    const token = localStorage.getItem("token");
    const vendorId = localStorage.getItem("vendor_id");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/api/v1/analytics/sales`;
    const response = await httpClient(url, { headers });
    return response.json;
  },

  getRevenueDetails: async (timePeriod) =>{
    const token = localStorage.getItem("token");
    const vendorId = localStorage.getItem("vendor_id");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/api/v1/analytics/revenueInsights?period=${timePeriod}`;
    const response = await httpClient(url, { headers });
    return response.json;
  },

  GetTopProducts: async ()=>{
    const token = localStorage.getItem("token");
    const vendorId = localStorage.getItem("vendor_id");
    const headers = new Headers({ Authorisation: token });
    const url = `${apiUrl}/api/v1/analytics/topProducts`;
    const response = await httpClient(url, { headers });
    return response.json;
  }
};
