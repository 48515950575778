const menuMeta = JSON.parse(
  localStorage.getItem("RaStore.fetch.menu.metadata")
);
function getTheItemDataFromMeta(itemId) {
  let itemMeta = {};
  Object.keys(menuMeta.data).map((category) => {
    menuMeta.data[category].map((itemData) => {
      if (itemData.id == itemId) {
        itemMeta.name = itemData.name;
        itemMeta.price = itemData.price;
        itemMeta.category_name = itemData.category_name;
        itemMeta.tax_group_id = itemData.tax_group_id;
      }
    });
  });
  return itemMeta;
}

function removedTheExpiredData(orderPlacedData) {
  let finalResult = [];
  let currentTime = new Date();
  orderPlacedData.map((orderData) => {
    if (orderData.date) {
      let timeDiff =
        (currentTime.getTime() - new Date(orderData.date).getTime()) /
        (1000 * 3600 * 24);
      if (timeDiff < 1) {
        finalResult.push(orderData);
      }
    }
  });
  return finalResult;
}

function hasOrderId(localStorageData, orderId) {
  let result = false;
  localStorageData.map((data) => {
    if (data.orderId == orderId) result = true;
  });
  return result;
}

function hasOrderIdForApproval(localStorageData, orderId) {
  let result = false;
  localStorageData.map((data) => {
    if (data.orderId == orderId) result = true;
  });
  return result;
}

function orderCreationHandler(data) {
  return new Promise((resolve, reject) => {
    try {
      let newOrderDataToStore = {};
      let orderCreationHistoricalData = localStorage.getItem(
        "RaStore.recent.order.placed"
      );
      orderCreationHistoricalData =
        orderCreationHistoricalData != null
          ? JSON.parse(orderCreationHistoricalData)
          : [];
      orderCreationHistoricalData = removedTheExpiredData(
        orderCreationHistoricalData
      );
      if (!hasOrderId(orderCreationHistoricalData, data.orderId)) {
        newOrderDataToStore.orderId = data.orderId;
        newOrderDataToStore.date = new Date();
        newOrderDataToStore.itemIds = data.itemIds;
        if (data.itemIds) {
          data.itemIds.map((itemId) => {
            //get the data of the itemids
            newOrderDataToStore[itemId] = getTheItemDataFromMeta(itemId);
          });
        }
        orderCreationHistoricalData.unshift(newOrderDataToStore);
        localStorage.setItem(
          "RaStore.recent.order.placed",
          JSON.stringify(orderCreationHistoricalData)
        );
      }
      return resolve(orderCreationHistoricalData);
    } catch (error) {
      console.log("error in storing the event ", error);
      return reject(
        new Error("error in setting the order data in localstorage")
      );
    }
  });
}

function orderPaymentApprovalHandler(data) {
  return new Promise((resolve, reject) => {
    try {
      let newOrderForApprovalStore = {};
      let orderForApprovalLocalStorage = localStorage.getItem(
        "RaStore.pending.approval.order"
      );
      orderForApprovalLocalStorage =
        orderForApprovalLocalStorage != null
          ? JSON.parse(orderForApprovalLocalStorage)
          : [];
      if (!hasOrderIdForApproval(orderForApprovalLocalStorage, data.orderId)) {
        newOrderForApprovalStore.orderId = data.orderId;
        newOrderForApprovalStore.date = new Date();
        newOrderForApprovalStore.itemIds = data.itemIds;
        newOrderForApprovalStore.amount = data.order_amount;
        newOrderForApprovalStore.user_id = data.from_user;
        if (data.itemIds) {
          data.itemIds.map((itemId) => {
            //get the data of the itemids
            newOrderForApprovalStore[itemId] = getTheItemDataFromMeta(itemId);
          });
        }
        orderForApprovalLocalStorage.unshift(newOrderForApprovalStore);
        localStorage.setItem(
          "RaStore.pending.approval.order",
          JSON.stringify(orderForApprovalLocalStorage)
        );
      }
      return resolve(newOrderForApprovalStore);
    } catch (error) {
      console.log("error in storing the event for order approval ", error);
      return reject(
        new Error(
          "error in setting the order data for approval in localstorage"
        )
      );
    }
  });
}

function handleOrderApproval(orderId, status) {
  //after sending the event update the localstorage
  let orderPendingForApprovalStore = localStorage.getItem(
    "RaStore.pending.approval.order"
  );
  orderPendingForApprovalStore =
    orderPendingForApprovalStore != null
      ? JSON.parse(orderPendingForApprovalStore)
      : [];
  let orderPlacedStore = localStorage.getItem("RaStore.recent.order.placed");
  orderPlacedStore =
    orderPlacedStore != null ? JSON.parse(orderPlacedStore) : [];

  let updatedOrderApprovalList = [];
  let dataRemoved;
  orderPendingForApprovalStore.map((data) => {
    if (data.orderId != orderId) {
      updatedOrderApprovalList.push(data);
    } else {
      dataRemoved = data;
    }
  });
  localStorage.setItem(
    "RaStore.pending.approval.order",
    JSON.stringify(updatedOrderApprovalList)
  );

  if ((status = "accepted")) {
    let newOrderDataToStore = {};
    if (!hasOrderId(orderPlacedStore, orderId)) {
      newOrderDataToStore.orderId = dataRemoved.orderId;
      newOrderDataToStore.date = new Date();
      newOrderDataToStore.itemIds = dataRemoved.itemIds;
      if (dataRemoved.itemIds) {
        dataRemoved.itemIds.map((itemId) => {
          //get the data of the itemids
          newOrderDataToStore[itemId] = getTheItemDataFromMeta(itemId);
        });
      }
      orderPlacedStore.unshift(newOrderDataToStore);
      localStorage.setItem(
        "RaStore.recent.order.placed",
        JSON.stringify(orderPlacedStore)
      );
    }
  }
}

module.exports = {
  orderCreationHandler,
  orderPaymentApprovalHandler,
  handleOrderApproval,
};
