import { Create, SimpleForm, TextInput, DateInput, required, Show, SimpleShowLayout, TextField, RichTextField, DateField, BooleanField, Datagrid, ShowButton, EditButton, List, Edit, DateTimeInput } from 'react-admin';

export const tagCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name"  label="tag name" type="string"/>
        </SimpleForm>
    </Create>
);

export const tagShow=()=>(
    <Show>
        <SimpleShowLayout>
        <TextField source="name" />
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="created_at"/>
            <DateField source="updated_at"/>
        </SimpleShowLayout>
    </Show>
);

export const tagList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};

  export const tagEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name"  label="tag name" type="string"/>
            <DateTimeInput source="updated_at" />
        </SimpleForm>
    </Edit>
)