import { SvgIconProps } from '@mui/material';
import OrderIcon from './OrderIcon';
import SalesIcon from './SalesIcon';
import { calculateGrowth } from './salesData';

// Define the shape of the salesData object
interface SalesData {
  salesData: {
    currentDay: {
      totalSales: number;
      totalOrders: number;
      completedOrders: number;
      pendingOrders: number;
    };
    lastDay: {
      totalSales: number;
      totalOrders: number;
      completedOrders: number;
      pendingOrders: number;
    };
  };
}

export const getSalesData = (salesData: SalesData | null | undefined) => {
  if (!salesData || !salesData.salesData) {
    console.error("Invalid salesData provided");
    // Return an empty array or some default data if needed
    return [];
  }

  const { currentDay, lastDay } = salesData.salesData;

  // Ensure currentDay and lastDay are valid
  const totalSales = currentDay?.totalSales ?? 0;
  const totalOrders = currentDay?.totalOrders ?? 0;
  const completedOrders = currentDay?.completedOrders ?? 0;
  const pendingOrders = currentDay?.pendingOrders ?? 0;
  const lastTotalSales = lastDay?.totalSales ?? 0;
  const lastTotalOrders = lastDay?.totalOrders ?? 0;
  const lastCompletedOrders = lastDay?.completedOrders ?? 0;
  const lastPendingOrders = lastDay?.pendingOrders ?? 0;


  return [
    {
      label: 'Total Sales',
      value: totalSales,
      growth: calculateGrowth(totalSales, lastTotalSales),
      bgColor: '#c2d5ff',
      iconBackgroundColor: '#2c25e8',
      svgIcon: SalesIcon,
    },
    {
      label: 'Total Orders',
      value: totalOrders,
      growth: calculateGrowth(totalOrders, lastTotalOrders),
      bgColor: '#f0eccc',
      iconBackgroundColor: '#e86625',
      svgIcon: OrderIcon,
    },
    {
      label: 'Completed Orders',
      value: completedOrders,
      growth: calculateGrowth(completedOrders, lastCompletedOrders),
      bgColor: '#beedcc',
      iconBackgroundColor: '#47ad58',
      icon: 'ion:pricetag',
    },
    {
      label: 'Pending Orders',
      value: pendingOrders,
      growth: calculateGrowth(pendingOrders, lastPendingOrders),
      bgColor: '#d8c7f2',
      iconBackgroundColor: '#cc22f2',
      icon: 'material-symbols:person-add',
    },
  ];
};
