import React, { useState, useEffect } from "react";
import { useStore, useStoreContext } from "react-admin";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Typography, Box, IconButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { dataProvider } from "../dataprovider";
import BottomNavigationCustom from "../common/bottom_nav";
import emptyCartImage from "../../images/emptyCartBG.png";
import animationData from "../../images/orderplaced.json";
import Lottie from "react-lottie";

function getTheTax(itemCount, tax) {
  var sum = 0;
  Object.keys(itemCount).forEach((item) => {
    if (itemCount[item].count > 0) {
      sum += itemCount[item].count * itemCount[item].price;
    }
  });
  return sum * (tax * 0.01);
}

function getTheTotal(itemCount, tax) {
  var sum = 0;
  Object.keys(itemCount).forEach((item) => {
    if (itemCount[item].count > 0) {
      sum += itemCount[item].count * itemCount[item].price;
    }
  });
  return sum + getTheTax(itemCount, tax);
}

function getTheListItem(
  itemCount,
  key,
  setItemLevelCount,
  setCartCount,
  cartCount
) {
  if (itemCount[key] && itemCount[key].count > 0) {
    return (
      <ListItem
        alignItems="center"
        key={key}
        secondaryAction={
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <IconButton
              onClick={() => {
                let updatedObject = { ...itemCount };
                if (updatedObject[key] && updatedObject[key].count > 0) {
                  updatedObject[key].count--;
                  setItemLevelCount(updatedObject);
                  setCartCount(cartCount - 1);
                }
              }}
              size="small"
              sx={{ marginRight: 1 }}
            >
              <RemoveIcon />
            </IconButton>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ minWidth: 20, textAlign: "center" }}
            >
              {itemCount[key].count}
            </Typography>
            <IconButton
              onClick={() => {
                let updatedObject = { ...itemCount };
                updatedObject[key].count++;
                setItemLevelCount(updatedObject);
                setCartCount(cartCount + 1);
              }}
              size="small"
              sx={{ marginLeft: 1 }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        }
      >
        <ListItemAvatar>
          <img
            alt={key}
            src={itemCount[key].image}
            style={{ width: 60, height: 60, marginRight: "5px" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={key}
          // Uncomment or adjust the secondary prop as needed
          // secondary={itemCount[key].meta}
          primaryTypographyProps={{ fontWeight: "bold" }}
          secondaryTypographyProps={{ color: "text.secondary" }}
        />
      </ListItem>
    );
  }
  return null;
}
const vibratingEffect = {
  "@keyframes vibratingEffect": {
    "0%, 100%": { transform: "translateX(0)" },
    "10%, 30%, 50%, 70%": { transform: "translateX(-10px)" },
    "20%, 40%, 60%": { transform: "translateX(10px)" },
    "80%": { transform: "translateX(8px)" },
    "90%": { transform: "translateX(-8px)" },
  },
  animation: "vibratingEffect 5s infinite", // Apply the animation
};

const Cart = () => {
  const store = useStoreContext();
  const [currentTable, setCurrentTable] = useState("");
  const [itemLevelCount, setItemLevelCount] = useStore("item.count.keeper", {});
  const [cartCount, setCartCount] = useStore("cart.total.number", 0);
  const [itemCount, setItemCount] = useState({});
  const [tableData, setTableData] = useState([]);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [menuData, setMenuData] = useStore("fetch.menu.metadata", null);

  useEffect(() => {
    setItemCount(store.getItem("item.count.keeper") || {});
    const unsubscribe = store.subscribe("item.count.keeper", (value) => {
      setItemCount(value || {});
    });
    dataProvider
      .getVendorTableDataCustom("vendor_table")
      .then(({ data }) => setTableData(data))
      .catch((error) => console.error(error));
    return () => unsubscribe();
  }, [store]);

  const handleCheckout = () => {
    //when chekout is done then empty the cart
    dataProvider
      .placeOrder("order", { data: { items: itemCount, table: currentTable } })
      .then(() => {
        resetCart();
        //set some flag which will open the success screen
        setOrderPlaced(true);
        //show the success for sometime
      })
      .catch((error) => console.error(error));
  };

  const resetCart = () => {
    setItemCount({});
    setItemLevelCount({});
    setCartCount(0);
  };

  const handleTableChange = (event) => {
    setCurrentTable(event.target.value);
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const hasNoItemsSelected = () =>
    Object.keys(itemCount).length === 0 ||
    Object.values(itemCount).every((item) => item.count === 0);

  if (orderPlaced) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Lottie options={defaultOptions} height={200} width={200} />
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#3f51b5", fontWeight: "bold" }}
          >
            Order Placed Successfully
          </Typography>
        </div>
        <BottomNavigationCustom />
      </>
    );
  }

  //if there is no element selected then just give cart empty
  if (hasNoItemsSelected()) {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="calc(100vh - 100px)"
          sx={{ backgroundColor: "white" }}
        >
          <Box textAlign="center">
            <img
              src={emptyCartImage} // Ensure this variable points to your empty cart image
              alt="Empty Cart"
              style={{
                width: "80%",
                maxWidth: "460px",
                height: "auto",
                borderRadius: "10px",
              }} // Responsive width, keeping height auto
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: "#3f51b5",
                fontWeight: "bold",
                mt: 2,
                ...vibratingEffect,
              }}
            >
              Your Cart is Empty, Order Now
            </Typography>
          </Box>
        </Box>
        <BottomNavigationCustom />
      </>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ padding: "20px", backgroundColor: "#f5f5f5" }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#3f51b5", fontWeight: "bold" }}
          >
            Items
          </Typography>
          <List
            sx={{
              bgcolor: "background.paper",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {hasNoItemsSelected() ? (
              <ListItem>
                <ListItemText primary="No Item selected" />
              </ListItem>
            ) : (
              Object.keys(itemCount).map((key) =>
                getTheListItem(
                  itemCount,
                  key,
                  setItemLevelCount,
                  setCartCount,
                  cartCount
                )
              )
            )}
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#3f51b5", fontWeight: "bold" }}
          >
            Summary
          </Typography>
          <List
            sx={{
              bgcolor: "background.paper",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {Object.keys(itemCount)
              .filter((key) => itemCount[key].count > 0)
              .map((key) => (
                <ListItem divider key={key}>
                  <ListItemText
                    primary={key}
                    secondary={`Price: ${itemCount[key].price} x ${itemCount[key].count}`}
                  />
                  <Typography variant="body2">{`Subtotal: ${
                    itemCount[key].count * itemCount[key].price
                  }`}</Typography>
                </ListItem>
              ))}

            <ListItem>
              <ListItemText primary="Tax" />
              <Typography variant="body2">{`₹${getTheTax(
                itemCount,
                menuData.tax
              ).toFixed(2)}`}</Typography>
            </ListItem>
            <ListItem>
              <ListItemText primary="Total" />
              <Typography
                variant="body2"
                sx={{ color: "#3f51b5", fontWeight: "bold" }}
              >{`₹${getTheTotal(itemCount, menuData.tax).toFixed(
                2
              )}`}</Typography>
            </ListItem>
          </List>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "50px",
          }}
        >
          <FormControl sx={{ width: 150, marginRight: "10px" }}>
            <InputLabel id="table-select-label">Select Table</InputLabel>
            <Select
              labelId="table-select-label"
              id="table-select"
              value={currentTable}
              label="Select Table"
              onChange={handleTableChange}
            >
              {tableData.map((table) => (
                <MenuItem
                  key={table.id}
                  value={table.table_no}
                >{`Table ${table.table_no}`}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleCheckout}
            disabled={hasNoItemsSelected() || !currentTable}
            sx={{
              backgroundColor: "#3f51b5",

              "&:hover": { backgroundColor: "#334296" },
              width: 100,
            }}
          >
            Checkout
          </Button>
        </Grid>
      </Grid>
      <BottomNavigationCustom />
    </>
  );
};

export default Cart;
