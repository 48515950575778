import { Card, CardContent, Grid, Typography } from '@mui/material';
import IconifyIcon from './IconifyIcon';

const SaleCard = (item:any ) => {
  const { value, label, growth, bgColor, iconBackgroundColor, icon, svgIcon: SvgIcon } = item.item;

  const Icon = icon ? (
    <IconifyIcon icon={icon} sx={{ fontSize: 15, color: 'common.white' }} />
  ) : SvgIcon ? (
    <SvgIcon sx={{ fontSize: 18 }} />
  ) : null;

  return (
    <Card
      sx={{
        borderRadius: 4,
        bgcolor: bgColor,
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.15) 0px 3px 3px',
        m: 1,
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                backgroundColor: iconBackgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Icon}
            </div>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6" color="primary.darker">
              {value}
            </Typography>
            <Typography color="grey.800" component="p" mb={1} sx={{ fontSize: '13px' }}>
              {label}
            </Typography>
            <Typography variant="caption" color="blue" component="p">
              Last day {growth}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SaleCard;
