import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import { Create, CreateButton, Datagrid, DateField, DateInput, DateTimeInput, Edit, EditButton, List, RichTextField, Show, ShowButton, SimpleForm, SimpleShowLayout, TextField, TextInput, UrlField } from "react-admin";
import { Utils } from "./utils";

export const itemDescriptionCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name"  label="item name" type="string"/>
            <TextInput source="image_url"  label="image url"/>
            <TextInput source="meta"  label="meta" type="string"/>
            <RichTextInput source="description_html" toolbar={<RichTextInputToolbar size="large"/> }  />
        </SimpleForm>
    </Create>
)

export const itemDescriptionEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name"  label="item name" type="string"/>
            <TextInput source="image_url"  label="image url"/>
            <TextInput source="meta"  label="meta" type="string"/>
            <RichTextInput source="description_html" toolbar={<RichTextInputToolbar size="large"/> }  />
            <DateTimeInput source="updated_at"/>
        </SimpleForm>
    </Edit>
)

export const itemDescriptionList = () => {
    return(
    <List>
      <Datagrid rowClick="edit">
      <TextField source="id" />
        <TextField source="name" />
        <RichTextField source="description_html" />
        <UrlField source="image_url" />
        <TextField source="meta" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ShowButton/>
        <EditButton/>
      </Datagrid>
    </List>
  )};
