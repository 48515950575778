import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItemAccordian from "./menu_item";

const MenuAccordian = ({ category, index, filteredMenu, isLoading, setIsLoading }) => {

    return (
        <>
            <Accordion defaultExpanded key={index} sx={{ backgroundColor: "#546E7A", color: '#FFFFFF', marginBottom: "10px", borderRadius: "5px", '&:before': { display: "none" } }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                    <Typography>{category}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#CFD8DC' }}>
                    <List>
                        {filteredMenu[category].map((item) => (
                            <MenuItemAccordian item={item} isLoading={isLoading} setIsLoading={setIsLoading} />
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default MenuAccordian;
