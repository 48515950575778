import React, { useState, useEffect } from "react";
import { useStore, useStoreContext } from "react-admin";
import BottomNavigationCustom from "../common/bottom_nav";
import { dataProvider } from "../dataprovider";
import {
  Button,
  useTheme,
  useMediaQuery,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import "../css/OrderTable.css";
import "../../admin/utils";
import status from "../common/OrderStatus";
import itemstatus from "../common/ItemStatus";
import { Utils } from "../../admin/utils";
import InvoiceModal from "../../admin/custom/InvoiceModal";
import OrderReceiverModal from "../common/basic_order_modal";

const Order = () => {
  const store = useStoreContext();
  const [orders, setOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [tableNo, setTableNo] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [orderStatus, setOrderStatus] = useStore("order.status", "");
  const [isOrderSearchActive, setIsOrderSearchActive] = useState(true);
  const [open, setOpen] = useState(false);
  const [countsOfOrders, setCountsOfOrders] = useState([]);
  const [disableNP, setDisableNP] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const getAbbreviatedStatus = (key) => key.slice(0, 4);
  const [isOrderModalOpenForManaged, setIsOrderModalOpenForManaged] = useStore(
    "modal.order.placed.managed",
    false
  );

  const handleOpen = (id) => {
    setInvoiceId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const limit = 10;

  const fetchOrders = async (page) => {
    try {
      const response = await dataProvider.getOrdersByVendorId(
        "order",
        page,
        limit
      );
      const data = await response.json.data;
      setOrders(data);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const fetchCountsOrder = async () => {
    try {
      const resp = await dataProvider.getCountsOfOrders();
      setCountsOfOrders(resp);
    } catch (error) {
      console.log("Error Fetching Count of Order API", error);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage);
    fetchCountsOrder();
  }, [currentPage]);

  const handleRowClick = (orderId) => {
    setExpandedOrderId((prevId) => (prevId === orderId ? null : orderId));
  };
  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    fetchOrders(nextPage);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const previousPage = currentPage - 1;
      fetchOrders(previousPage);
    }
  };

  const handleStatusSelect = async (e) => {
    setDisableNP(true);
    let statusValue = null;
    setSelectedStatus(e.target.value);
    if (e.target.value === "pending") {
      statusValue = 0;
    } else if (e.target.value === "completed") {
      statusValue = 4;
    } else if (e.target.value === "accepted") {
      statusValue = 1;
    } else if (e.target.value === "cooking") {
      statusValue = 2;
    } else if (e.target.value === "prepared") {
      statusValue = 3;
    }

    const filteredOrders = await dataProvider.filteredStatusOrders(
      currentPage,
      limit,
      statusValue
    );
    setOrders(filteredOrders);
  };
  const handleClearFilters = () => {
    setOrderId("");
    setTableNo("");
    setSelectedStatus("");
    setIsOrderSearchActive(true);
    fetchOrders(1);
    setDisableNP(false);
  };

  const handleStatusChange = async (id, newStatus, type, event) => {
    event.stopPropagation();
    const updatedOrders = orders.map((order) => {
      if (order.id === id) {
        return { ...order, status: newStatus };
      }
      return order;
    });
    setOrders(updatedOrders);
    setOrderStatus(newStatus);
    const resp = await dataProvider.generalUpdateStatus(
      orders,
      id,
      newStatus,
      type
    );
    setOrders(resp);
    fetchCountsOrder();
  };

  const handleOrderIdChange = (e) => {
    setDisableNP(true);
    setOrderId(e.target.value);
    setIsOrderSearchActive(true); // Activate order search
    setTableNo("");
    if (e.target.value) {
      setTimeout(() => {
        searchOrders(e.target.value, null, currentPage);
      }, 2000);
    }
  };

  const handleTableNoChange = (e) => {
    setDisableNP(true);
    setTableNo(e.target.value);
    setIsOrderSearchActive(false); // Activate table No search
    setOrderId("");

    if (e.target.value) {
      setTimeout(() => {
        searchOrders(null, e.target.value, currentPage);
      }, 2000);
    }
  };

  const searchOrders = async (orderId, tableNo, currentPage) => {
    try {
      const response = await dataProvider.searchOrders(
        orderId,
        tableNo,
        currentPage
      );
      const data = await response;
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const getBodyOfModal = () => {
    return (
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          New Order Placed
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          A new order has been placed. Please refresh the screen to see the
          latest updates.
        </Typography>
        <Button
          onClick={() => {
            setIsOrderModalOpenForManaged(!isOrderModalOpenForManaged);
            window.location.reload();
          }}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Refresh
        </Button>
      </Box>
    );
  };

  return (
    <>
      <div>
        <div className="header">
          <h4>Manage Orders</h4>
        </div>
        <div className="summary">
          <div className="box completed">
            <p>Completed Orders</p>
            <p>{countsOfOrders.completedCount}</p>
          </div>
          <div className="box pending">
            <p>Pending Orders</p>
            <p>{countsOfOrders.pendingCount}</p>
          </div>
        </div>
        <div className="filters">
          <input
            type="text"
            placeholder="Search by Order ID"
            value={orderId}
            onChange={handleOrderIdChange}
            disabled={!isOrderSearchActive}
          />
          <input
            type="text"
            placeholder="Search by Table No"
            value={tableNo}
            onChange={handleTableNoChange}
            disabled={!isOrderSearchActive}
          />
          <select value={selectedStatus} onChange={handleStatusSelect}>
            <option value="">Select Status</option>
            <option value="pending">Pending</option>
            <option value="accepted">Accepted</option>
            <option value="cooking">Cooking</option>
            <option value="prepared">Prepared</option>
            <option value="completed">Completed</option>
          </select>
          <Button variant="contained" onClick={handleClearFilters}>
            Clear Filters
          </Button>
        </div>
        <div className="orders-table-container">
          <div className="orders-table">
            <div className="table-header">
              <div>Order No</div>
              <div>Table No</div>
              <div>Total</div>
              {/* <div>Status</div> */}
              <div>Placed At</div>
              <div>Status</div>
              <div>Invoice</div>
            </div>
            {orders.map((order) => (
              <React.Fragment key={order.id}>
                <div
                  className={`table-row ${
                    expandedOrderId === order.id ? "expanded" : ""
                  }`}
                  onClick={() => handleRowClick(order.id)}
                >
                  <div>{order.id}</div>
                  <div>{order.table_no || "-"}</div>
                  <div>
                    ₹
                    {order.order_item
                      .reduce((total, item) => {
                        let itemTotal = item.quantity * item.item.price;
                        const totalTax = item.item.taxes.reduce(
                          (taxTotal, tax) => {
                            return (
                              taxTotal + (itemTotal * tax.percentage) / 100
                            );
                          },
                          0
                        );
                        itemTotal += totalTax;
                        return total + itemTotal;
                      }, 0)
                      .toFixed(2)}
                  </div>
                  <div>{Utils.getTimeAgo(order.created_at)}</div>
                  <div>
                    <div
                      className="action-dropdown"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <select
                        value={order.status}
                        onChange={(e) =>
                          handleStatusChange(
                            order.id,
                            e.target.value,
                            "customer_order",
                            e
                          )
                        }
                      >
                        {Object.keys(status).map((key) => (
                          <option key={status[key]} value={status[key]}>
                            {isSmallScreen ? getAbbreviatedStatus(key) : key}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => handleOpen(order.id)}
                      size={isSmallScreen ? "small" : "medium"} // Conditionally change the button size
                      sx={{
                        fontSize: isSmallScreen ? "0.7rem" : "0.7rem", // Adjust font size for better readability on small screens
                        padding: isSmallScreen ? "0px 4px" : "2px 4x",
                      }}
                    >
                      {isSmallScreen
                        ? getAbbreviatedStatus("Invoice")
                        : "Invoice"}
                    </Button>
                  </div>
                </div>

                {expandedOrderId === order.id && (
                  <div
                    className="additional-details"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {order.order_item.map((item) => (
                      <div key={item.id} className="food-item">
                        {item.quantity} x {item.item.item_description.name} - ₹{" "}
                        {item.quantity * item.item.price}
                        <select
                          value={item.status}
                          onChange={(e) =>
                            handleStatusChange(
                              item.id,
                              e.target.value,
                              "order_item",
                              e
                            )
                          }
                        >
                          {Object.keys(itemstatus).map((key) => (
                            <option
                              key={itemstatus[key]}
                              value={itemstatus[key]}
                            >
                              {key}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        {invoiceId && (
          <InvoiceModal
            open={open}
            handleClose={handleClose}
            number={invoiceId}
          />
        )}
        {disableNP ? (
          <div style={{ marginBottom: "50px" }}>
            {/* You can place any content you want here, or leave it empty */}
          </div>
        ) : (
          <div className="pagination">
            <Button variant="contained" onClick={handleLoadMore}>
              Next
            </Button>
            <Button
              variant="contained"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
          </div>
        )}
        <BottomNavigationCustom />
      </div>
      <OrderReceiverModal
        isOpen={isOrderModalOpenForManaged}
        onClose={() => {
          setIsOrderModalOpenForManaged(!isOrderModalOpenForManaged);
        }}
        title="OrderAlert"
        description="new order received referesh page by clicking button"
        body={getBodyOfModal()}
      />
    </>
  );
};

export default Order;
