import { useState, useEffect, useRef } from "react";
import {
  useMediaQuery,
  Box,
  Typography,
  TextField,
  LinearProgress,
  useTheme,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  CircularProgress,
} from "@mui/material";

import PrintIcon from "@mui/icons-material/Print";
import { useDataProvider, useStore, useStoreContext } from "react-admin";
import BottomNavigationCustom from "../common/bottom_nav";
import _ from "lodash";
import MenuAccordian from "./menu_accordian";
import MenuItemAccordian from "./menu_item";
import InvoiceModal from "../../admin/custom/InvoiceModal";

const ItemMenu = () => {
  const store = useStoreContext();
  const theme = useTheme();
  const dataProvider = useDataProvider();
  const [menu, setMenu] = useState({});
  const [filteredMenu, setFilteredMenu] = useState({});
  const [taxRate, setTaxRate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [itemLevelCount, setItemLevelCount] = useStore("item.count.keeper", {});
  const [search, setSearch] = useState("");
  const [tableNumber, setTableNumber] = useState([]);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [currentTable, setCurrentTable] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [menuData, setMenuData] = useStore("fetch.menu.metadata", null);
  const [page, setPage] = useState(0);
  const scrollRef = useRef(null);
  const previousScrollTop = useRef(0);
  const [cartCount, setCartCount] = useStore("cart.total.number", 0);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (id) => {
    setInvoiceNumber(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // Reset the necessary states here
    setItemLevelCount({});
    setCurrentTable("");
    setCartCount(0);
    setOrderPlaced(false);
    setSearch("");
  };

  const dataIsExpired = (timestamp) => {
    const millisecondsInADay = 30 * 60 * 1000;
    const currentTimestamp = Date.now();
    return currentTimestamp > timestamp + millisecondsInADay;
  };

  const getTotalLengthOdMenu = (data) => {
    let itemLength = 0;
    Object.keys(data).map((category) => {
      itemLength = itemLength + data[category].length;
    });
    return itemLength;
  };

  const getDataForMultiplePages = (pageArray, data) => {
    // get the data for all the pages in sequential way
    let result = null;
    pageArray.map((page) => {
      if (page > -1) {
        if (!result) {
          result = getDataByPage(page, data);
        } else {
          let pageResult = getDataByPage(page, data);
          Object.keys(pageResult).map((key) => {
            if (result[key]) {
              result[key].push(pageResult[key]);
            } else {
              result[key] = pageResult[key];
            }
          });
        }
      }
    });
    return result;
  };

  const getDataByPage = (page, data) => {
    if (page < 0) return null;
    let size = 10;
    let count = 10;
    let followUpIndex = 0;
    let start = page == 0 ? page * size : page * size - 1;
    let length = getTotalLengthOdMenu(data);
    let end = page * size + size;
    if (end > length) {
      end = length;
    }
    let pageData = {};

    Object.keys(data).map((category) => {
      for (let i = 0; i < data[category].length; i++) {
        if (count > 0 && followUpIndex >= start) {
          if (!pageData[category]) pageData[category] = [];
          pageData[category].push(data[category][i]);
          count--;
        }
        followUpIndex++;
      }
    });
    return pageData;
  };

  const setBaseData = (page, data, tax) => {
    let getPageData = getDataByPage(page, data);
    setMenu(getPageData);
    setFilteredMenu(getPageData);
    setTaxRate(tax / 100);
  };

  useEffect(() => {
    //check the store data if that exists then don't fetch or if it expires then fetch
    if (!menuData || dataIsExpired(menuData.timestamp)) {
      dataProvider
        .getMenuDataCustom("menu")
        .then(({ data, tax }) => {
          setMenuData({ data, tax, timestamp: Date.now() });
          setBaseData(page, data, tax);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      setBaseData(page, menuData.data, menuData.tax);
      setLoading(false);
    }
    dataProvider
      .getVendorTableDataCustom("vendor_table")
      .then(({ data }) => setTableNumber(data))
      .catch((error) => console.error(error));
    setItemLevelCount(store.getItem("item.count.keeper") || {});
  }, []);

  const searchTheMenu = (searchValue) => {
    const lowercasedSearch = searchValue.toLowerCase();
    const filtered = Object.keys(menuData.data).reduce((acc, curr) => {
      const filteredItems = menuData.data[curr].filter((item) =>
        item.name.toLowerCase().includes(lowercasedSearch)
      );
      if (filteredItems.length > 0) {
        acc[curr] = filteredItems;
      }
      return acc;
    }, {});
    setFilteredMenu(filtered);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    searchTheMenu(value);
  };

  const handleCheckout = () => {
    const itemCount = Object.entries(itemLevelCount).reduce(
      (acc, [_, item]) => {
        acc[item.name] = item;
        return acc;
      },
      {}
    );
    const placeOrderPayload = {
      data: { items: itemCount, table: currentTable },
    };
    dataProvider
      .placeOrder("order", placeOrderPayload)
      .then((response) => {
        setInvoiceNumber(response.data.id);
        setItemLevelCount({});
        setCartCount(0);
        setOrderPlaced(true);
      })
      .catch((error) => console.error(error));
  };

  const handlePrintInvoice = () => {
    if (orderPlaced) {
      console.log("Printing invoice...");
    }
  };

  const handleTableChange = (event) => {
    setCurrentTable(event.target.value);
  };

  const total = Object.values(itemLevelCount).reduce(
    (acc, item) => acc + item.count * item.price,
    0
  );
  const tax = total * taxRate;
  const totalWithTax = total + tax;

  const renderCartControls = () => (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
        bgcolor: "background.paper",
        borderTop: 1,
        borderColor: "divider",
        gap: 1,
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 0.5, flexGrow: 1 }}
      >
        <Typography variant="body2">Total: ₹{total.toFixed(2)}</Typography>
        <Typography variant="body2">Tax: ₹{tax.toFixed(2)}</Typography>
        <Typography variant="body2">
          Grand Total: ₹{totalWithTax.toFixed(2)}
        </Typography>
      </Box>
      <FormControl sx={{ width: 150, marginRight: "10px" }}>
        <InputLabel id="table-select-label">Select Table</InputLabel>
        <Select
          labelId="table-select-label"
          id="table-select"
          value={currentTable}
          label="Select Table"
          onChange={handleTableChange}
        >
          {tableNumber.map((table) => (
            <MenuItem
              key={table.id}
              value={table.table_no}
            >{`Table ${table.table_no}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          variant="contained"
          onClick={handleCheckout}
          disabled={!tableNumber || orderPlaced}
          sx={{ whiteSpace: "nowrap" }}
        >
          Checkout
        </Button>
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => handleOpen(invoiceNumber)}
          disabled={!orderPlaced}
          sx={{ whiteSpace: "nowrap" }}
        >
          Invoice
        </Button>
      </Box>
      {invoiceNumber && (
        <InvoiceModal
          open={open}
          handleClose={handleClose}
          number={invoiceNumber}
        />
      )}
    </Box>
  );

  const handleScrollingOfMenu = () => {
    if (loading) return;
    const currentScrollTop = scrollRef.current.scrollTop;
    const scrollHeight = scrollRef.current.scrollHeight;
    const clientHeight = scrollRef.current.clientHeight;

    if (currentScrollTop > previousScrollTop.current) {
      if (currentScrollTop + clientHeight > scrollHeight - 100) {
        setLoading(true);
        //make the page fetch for the next page and if all get successfull then increment
        let nextPageData = getDataForMultiplePages(
          [page, page + 1],
          menuData.data
        );
        if (Object.keys(nextPageData).length > 0) {
          setPage(page + 1);
          setMenu(nextPageData);
          setFilteredMenu(nextPageData);
        }
        setLoading(false);
      }
    } else {
      // fetch for the previous page and if we get the value returned then
      if (currentScrollTop === 0 && page != 0) {
        setLoading(true);
        let previousPageData = getDataForMultiplePages(
          [page - 1, page],
          menuData.data
        );
        if (Object.keys(previousPageData).length > 0) {
          setPage(page - 1);
          setMenu(previousPageData);
          setFilteredMenu(previousPageData);
        }
        setLoading(false);
      }
    }
    previousScrollTop.current = currentScrollTop;
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ marginBottom: "50px" }}>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search for items..."
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        InputProps={{
          style: {
            marginBottom: "16px",
            backgroundColor: "white",
            borderRadius: "15px",
          },
        }}
        sx={{ marginX: "3px", borderRadius: "15px" }}
      />
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Box
          ref={scrollRef}
          sx={{ flexGrow: 1, overflowY: "auto", height: "100vh" }}
          onScroll={handleScrollingOfMenu}
        >
          {loading ? (
            <LinearProgress sx={{ width: "100%" }} />
          ) : (
            Object.keys(filteredMenu).map((category, index) => (
              <MenuAccordian
                category={category}
                index={index}
                filteredMenu={filteredMenu}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ))
          )}
        </Box>
        {!isScreenSmall && (
          <Box
            sx={{
              width: 450,
              position: "sticky",
              top: 0,
              height: "calc(100vh - 56px)",
              overflowY: "auto",
              bgcolor: "#CFD8DC",
              padding: 2,
              borderRadius: "5px",
              boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Cart Items
            </Typography>
            <List>
              {Object.entries(itemLevelCount)
                .filter(([_, item]) => item.count > 0)
                .map(([key, item], index) => (
                  <MenuItemAccordian
                    item={item}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                ))}
            </List>
            {renderCartControls()}
          </Box>
        )}
      </Box>
      <BottomNavigationCustom />
    </Box>
  );
};

export default ItemMenu;
