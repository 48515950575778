import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { dataProvider } from '../../dataprovider';

const ApexChart = () => {
  const [timeRange, setTimeRange] = useState('day');
  const [revenueData, setRevenueData] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: 'area',
      height: 300,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Revenue Insights',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3a27c4',
      },
    },
    xaxis: {
      type: timeRange === 'day' ? 'datetime' : 'category',
      categories: [],
      labels: {
        style: {
          colors: '#666',
          fontSize: '12px',
        },
      },
      axisBorder: {
        color: '#ddd',
      },
      axisTicks: {
        color: '#ddd',
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#666',
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      theme: 'light',
    },
    grid: {
      borderColor: '#ddd',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        gradientToColors: ['#00c6ff'], // End color
        stops: [0, 100]
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: {
        colors: '#666',
        fontSize: '12px',
      },
    },
  });

  const [series, setSeries] = useState([{
    name: "Revenue",
    data: []
  }]);

  const handleTimeRangeChange = (event) => {
    const selectedTimeRange = event.target.value;
    setTimeRange(selectedTimeRange);
  };

  const fetchRevenueDetails = async () => {
    try {
      const data = await dataProvider.getRevenueDetails(timeRange);
      console.log("Revenue Details", data);
      setRevenueData(data.revenueInsights);
    } catch (error) {
      console.error("Failed to fetch revenue details:", error);
    }
  };

  useEffect(() => {
    fetchRevenueDetails();
  }, [timeRange]);

  useEffect(() => {
    if (revenueData.length > 0) {
      const categories = revenueData.map(item => {
        if (timeRange === 'day') {
          return new Date(item.label).getTime(); // Convert to timestamp for datetime xaxis
        }
        return item.label; // Use label directly for category xaxis
      });
      const data = revenueData.map(item => item.revenue);

      console.log("Categories:", categories);
      console.log("Data:", data);

      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories,
          type: timeRange === 'day' ? 'datetime' : 'category' // Adjust type based on time range
        }
      }));

      setSeries([{
        name: "Revenue",
        data
      }]);
    }
  }, [revenueData, timeRange]);

  return (
    <div style={styles.container}>
      <div style={styles.chartContainer}>
        <select onChange={handleTimeRangeChange} value={timeRange} style={styles.dropdown}>
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
        <ReactApexChart options={options} series={series} type="area" height={300} width="100%" />
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartContainer: {
    width: '100%',
    maxWidth: '1200px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#fff',
    padding: '15px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
    boxSizing: 'border-box',
  },
  dropdown: {
    padding: '8px',
    margin: '5px',
    fontSize: '12px',
    borderRadius: '5px',
    border: '2px solid #dedcf2',
    width: '120px',
    boxSizing: 'border-box',
  }
};

export default ApexChart;
