import React, { useState, useEffect } from "react";
import "../css/kitchenStyle.css";
import { dataProvider } from "../dataprovider";
import KitchenOrderCard from "./KitchenOrderCard";
import TabsView from "../common/OrdersTab";
import ItemAggregationPanel from "./kitchenleftview";
import OrderReceiverModal from "../common/basic_order_modal";
import { useStore, useStoreContext } from "react-admin";
import { Modal, Box, Typography, Button } from "@mui/material";

const KitchenView = () => {
  const [orders, setOrders] = useState([]);
  const [isOrderModalOpen, setIsOrderModalOpen] = useStore(
    "modal.order.placed.kitchen",
    false
  );
  const handleItemStatusChange = (orderId, itemId, event) => {
    event.stopPropagation();
    const updatedOrders = orders.map((order) => {
      if (order.id === orderId) {
        return {
          ...order,
          items: order.items.map((item) => {
            if (item.id === itemId) {
              return { ...item, status: event.target.value };
            }
            return item;
          }),
        };
      }
      return order;
    });
    setOrders(updatedOrders);
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getBodyOfModal = () => {
    return (
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          New Order Placed
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          A new order has been placed. Please refresh the screen to see the
          latest updates.
        </Typography>
        <Button
          onClick={() => {
            setIsOrderModalOpen(!isOrderModalOpen);
            window.location.reload();
          }}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Refresh
        </Button>
      </Box>
    );
  };

  return (
    <>
      <div className="containerview">
        <div className="leftpanel">
          <ItemAggregationPanel />
        </div>
        <div className="rightpanel">
          <TabsView
            orders={orders}
            onItemStatusChange={handleItemStatusChange}
          />
          {/* Color Legend Box */}
          <div className="colorLegend">
            <div>
              <span className="colorLegendCircle pending1"></span>Pending
            </div>
            <div>
              <span className="colorLegendCircle accepted"></span>Accepted
            </div>
            <div>
              <span className="colorLegendCircle cooking"></span>Cooking
            </div>
            <div>
              <span className="colorLegendCircle prepared"></span>Prepared
            </div>
          </div>
        </div>
      </div>
      <OrderReceiverModal
        isOpen={isOrderModalOpen}
        onClose={() => {
          setIsOrderModalOpen(!isOrderModalOpen);
        }}
        title="OrderAlert"
        description="new order received referesh page by clicking button"
        body={getBodyOfModal()}
      />
    </>
  );
};

export default KitchenView;
