import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from "@mui/material";
import Sales from "../AnalyticsDashBoard/todays-sales/Sales";
import UnifiedOrderNotificationCenter from "./UnifiedNotificationApproval";
import ApexChart from "../AnalyticsDashBoard/charts/Chart";
import CustomerSatisfaction from "../AnalyticsDashBoard/charts/CustomerSatisfaction";
import TopProductsChart from "../AnalyticsDashBoard/charts/TopProductsChart";
import { dataProvider } from "../dataprovider";

const AnalyticsDash = () => {
  const [salesData, setSalesData] = useState(null);
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const mainContainer = {
    backgroundColor: '#f7f8fa',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
    padding: '10px',
    fontFamily: 'Roboto, sans-serif'
  };

  const containerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '5px',
    width: '100%',
    boxSizing: 'border-box',
  };

  const itemStyles = {
    flex: '1 1 calc(50% - 20px)',
    minWidth: '300px',
    boxSizing: 'border-box',
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      alert("Please select Menu Image");
      return;
    }
    await dataProvider.ImageUpload(image);
    setImage(null);
    setPreview(null);
    setLoading(false);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);

      // Create a preview of the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchSalesData = async () => {
    try {
      const data = await dataProvider.GetSalesData();
      setSalesData(data);
    } catch (error) {
      console.error("Failed to fetch sales data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTopProducts = async () => {
    try {
      const data = await dataProvider.GetTopProducts();
      setTopProducts(data.topProducts);
    } catch (error) {
      console.error("Failed to fetch Top Products:", error);
    }
  };

  useEffect(() => {
    fetchSalesData();
    fetchTopProducts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={mainContainer}>
      <div style={{ marginBottom: '5px' }}><Sales salesData={salesData} /></div>
      <div style={containerStyles}>
        <div style={itemStyles}>
          <ApexChart />
        </div>
        <div style={itemStyles}>
          <UnifiedOrderNotificationCenter />
        </div>
      </div>
      <div style={containerStyles}>
        <div style={itemStyles}><CustomerSatisfaction /></div>
        <div style={itemStyles}><TopProductsChart data={topProducts} /></div>
      </div>
      <div style={containerStyles}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "400px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.15) 0px 3px 3px',
            padding: "20px",
            marginTop: "5px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "15px",
              fontFamily: "Roboto, sans-serif",
              fontSize: "22px",
              fontWeight: "bold",
              color: "#3a27c4",
            }}
          >
            Upload Your Menu
          </Typography>

          {loading ? (
            <div>Inserting data wait patiently</div>
          ) : (
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{
                  padding: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
              {preview && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <img
                    src={preview}
                    alt="Image Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px",
                  borderRadius: "5px",
                  backgroundColor: "skyblue",
                  "&:hover": {
                    backgroundColor: "#1e90ff",
                  },
                }}
              >
                Submit
              </Button>
            </form>
          )}
        </Box>
      </div>
    </div>
  );
}

export default AnalyticsDash;
