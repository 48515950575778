import React from "react";
import ReactApexChart from "react-apexcharts";

const TopProductsChart = ({ data }) => {
  const options = {
    chart: {
      type: 'bar',
      height: 300,
      toolbar: {
        show: false,
      },
    },
    title: {
      text: 'Top Products',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#3a27c4',
      },
    },
    xaxis: {
      categories: data.map(product => product.name),
      labels: {
        style: {
          colors: '#666',
          fontSize: '10px',
        },
        rotate: -45,
        maxHeight: 100,
      },
      axisBorder: {
        color: '#ddd',
      },
      axisTicks: {
        color: '#ddd',
      },
    },
    yaxis: {
      title: {
        text: 'Total Sold',
        style: {
          color: '#3a27c4',
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'Roboto, sans-serif',
        },
      },
      labels: {
        style: {
          colors: '#3a27c4',
          fontSize: '10px',
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '50%',
        distributed: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "vertical",
        gradientToColors: ['#00c6ff'], // End color
        stops: [0, 100]
      },
      colors: ['#007bff']
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#3a27c4'],
        fontSize: '10px',
      },
    },
    tooltip: {
      theme: 'light',
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  const series = [{
    name: 'Total Sold',
    data: data.map(product => parseInt(product.total_sold)),
  }];

  return (
    <div style={styles.chartContainer}>
      <ReactApexChart options={options} series={series} type="bar" height={295} />
    </div>
  );
};

const styles = {
  chartContainer: {
    width: '100%',
    marginTop:'3px',
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 5px 10px, rgba(0, 0, 0, 0.15) 0px 3px 3px',
    fontFamily: 'Roboto, sans-serif',
    
  },
};

export default TopProductsChart;
