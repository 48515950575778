import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  List,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

export const itemCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="enabled" label="enabled" type="number" />
      <TextInput
        source="item_description_id"
        label="item description id"
        type="number"
      />
      <TextInput source="price" label="price" type="number" />
      <TextInput source="tax_group_id" label="tax group id" type="number" />
      <TextInput source="category_id" label="category id" type="number" />
      <TextInput source="tag_group_id" label="tag group id" type="string" />
      <TextInput source="offer_id" label="offer id" type="number" />
    </SimpleForm>
  </Create>
);

export const itemList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="enabled" />
        <TextField source="item_description_id" />
        <TextField source="price" />
        <TextField source="tax_group_id" />
        <TextField source="category_id" />
        <TextField source="tag_group_id" />
        <TextField source="offer_id" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const itemEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="enabled" label="enabled" type="number" />
      <TextInput
        source="item_description_id"
        label="item description id"
        type="number"
      />
      <TextInput source="price" label="price" type="number" />
      <TextInput source="tax_group_id" label="tax group id" type="number" />
      <TextInput source="category_id" label="category id" type="number" />
      <TextInput source="tag_group_id" label="tag group id" type="string" />
      <TextInput source="offer_id" label="offer id" type="number" />
      <DateTimeInput source="updated_at" />
    </SimpleForm>
  </Edit>
);
