
import React, { useState, useEffect } from 'react';
import KitchenOrderCard from '../custom/KitchenOrderCard';
import { dataProvider } from "../dataprovider";
import "../css/tabview.css";

const TabsView = () => {
    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState('ALL');

    useEffect(() => {
      const fetchOrders = async () => {
        try {
          let response = await dataProvider.getPendingOrder();
          setOrders(response);
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      };
  
      fetchOrders();
    }, []);

  const tabCounts = {
    ALL: orders.length,
    PENDING: orders.filter(order => order.status === 0).length,
    ACCEPTED: orders.filter(order => order.status === 1).length,
    COOKING: orders.filter(order => order.status === 2).length,
    PREPARED: orders.filter(order => order.status === 3).length,
  };

  const renderOrders = (status) => {
    return orders
      .filter(order => status === 'ALL' || order.status === status)
      .map(order => (
        <KitchenOrderCard 
          key={order.id} 
          order={order}
          onOrderStatusChange={handleOrderStatusChange}
        />
      ));
  };

  const handleOrderStatusChange = async (orderId, newStatus) => {
    const order = orders.find(order => order.id === orderId);
  
    if (!order) {
      console.error("Order not found");
      return;
    }
  
    // If the current status is 'Prepared' (assuming status 3 is 'Prepared')
    // and the new status is not 'Cooking' (assuming status 2 is 'Cooking'),
    // prevent the status change and alert the user.
    if (order.status === 3 && newStatus !== 2) {
      alert("An order in the 'Prepared' state can only move back to the 'Cooking' state.");
      return;
    }
  
    try {
      
      const response = await dataProvider.updateStatus(orderId, newStatus, 'customer_order');
      if (response.ok) {
        const updatedOrders = orders.map(order => {
          if (order.id === orderId) {
            return { ...order, status: newStatus };
          }
          return order;
        });
        setOrders(updatedOrders);
      } else {
        alert('Error updating order status');
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  return (
    <div className="container">
      <div className="tabs">
        {Object.entries(tabCounts).map(([status, count]) => (
          <button
            key={status}
            onClick={() => setActiveTab(status)}
            className={`tabButton ${activeTab === status ? 'active' : ''}`}
          >
            {status} ({count})
          </button>
        ))}
      </div>

      
      <div className="orderCardsContainer">
        {activeTab === 'ALL' && renderOrders('ALL')}
        {activeTab === 'PENDING' && renderOrders(0)}
        {activeTab === 'ACCEPTED' && renderOrders(1)}
        {activeTab === 'COOKING' && renderOrders(2)}
        {activeTab === 'PREPARED' && renderOrders(3)}
      </div>
    </div>
  );
};

export default TabsView;
