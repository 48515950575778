// KitchenOrderCard.js
import React from 'react';
import "../css/kitchenStyle.css";
import { formatDistanceToNow } from 'date-fns';
import { useState, useEffect } from "react";
import { useStore, useStoreContext } from "react-admin";

const statusColors = {
  0: '#f0ad4e', // Pending color
  1: '#5bc0de', // Accepted color
  2: '#ff6347', // Cooking color
  3: '#5cb85c', // Prepared color
};

const KitchenOrderCard = ({ order, onOrderStatusChange }) => {
  const store = useStoreContext();
  
  const [orderStatus, setOrderStatus] = useStore('order.status', store.getItem('order.status'));
  const [orderStat, setOrderStat] = useState(store.getItem('order.status'));
  store.subscribe('order.status', (value) => {
      setOrderStat(value);
  });
  

  
  

  
  const formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true ,timeZone: 'Asia/Kolkata'};
    return new Date(dateString).toLocaleTimeString('en-US', options);
  };

  const handleCardClick = () => {
    let nextStatus;
    if(order.status===3){
       nextStatus=2;
    }else{
       nextStatus = order.status < 3 ? order.status + 1 : 0;
    }
    
    
    onOrderStatusChange(order.id, nextStatus); // Propagate the change up to TabsView for handling
  };

  return (
      <div className="orderCard" onClick={handleCardClick} style={{ backgroundColor: statusColors[order.status] }}>
          <div className="orderHeader">
              <div className="orderTime">{formatDate(order.created_at)}</div>
              <div>{formatDistanceToNow(new Date(order.created_at), { addSuffix: true })}</div>
              <div>
              
              <div>Table: {order.table_no || ""}</div>
              <div className="orderNumber">#{order.id}</div>
              </div>
          </div>
          <div className="orderDetails">
              {order.order_item.map((item) => (
              <div key={item.id} className="orderItem">
                  <span className="quantity">{item.quantity}x</span> {item.item_name}
                  
              </div>
              ))}
          </div>
      </div>
  );
};

export default KitchenOrderCard;
