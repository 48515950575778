import { Modal, Box, Typography, Button } from "@mui/material";
const OrderReceiverModal = ({ isOpen, onClose, title, description, body }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby={title}
      aria-describedby={description}
    >
      {body}
    </Modal>
  );
};

export default OrderReceiverModal;
