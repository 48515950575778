import React, { useState, useEffect } from "react";
import {Button,TextField,FormControl,InputLabel,Select,MenuItem,Box,Typography,Container,
  Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Grid, Card, CardContent, Avatar,Paper, CardHeader} from "@mui/material";
import { dataProvider } from "../dataprovider";
import PersonIcon from "@mui/icons-material/Person";
import Profile from "./profile";

const UserAccess = () => {
  const [showForm, setShowForm] = useState(false);
  const [showEmployeeInfoForm, setShowEmployeeInfoFrom] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [employeeInfoDetail, setEmployeeInfoDetail] = useState(null);
  const [roles, setRoles] = useState([]);
  const [vendorEmployees, setVendorEmployees] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    phone: "",
    identifier: "",
    role_id: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalStatus, setModalStatus] = useState("error");

  useEffect(() => {
    dataProvider.fetchRoles().then(setRoles).catch(console.error);
    fetchVendorEmployees();
  }, []);

  const fetchVendorEmployees = async () => {
    try {
      const employees = await dataProvider.vendorEmployees();
      setVendorEmployees(employees);
    } catch (error) {
      console.error("Failed to fetch vendor employees:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const response = await dataProvider.createUserEmployee(formData);
      const postresp = response.json; 
      if (postresp.status === "success") {
        if (!postresp.message) {
          setModalContent(postresp.user_created[0]); 
          setModalStatus("success");
          fetchVendorEmployees();
          handleClearForm();
          
        } else if (postresp.message === "user already exist") {
          setModalContent("User Name already exist");
          setModalStatus("exists");
          handleClearForm();

        }
      } else {
        setModalContent("Error on User Creation");
        setModalStatus("error");
        handleClearForm();
      }
      setOpenModal(true);
    } catch (error) {
      console.error("Error submitting form: ", error);
      setModalContent("Error on User Creation");
      setModalStatus("error");
      setOpenModal(true);
    }
  };
  const handleSubmitEmpInfo = async (e) => {
    e.preventDefault();
    const response = await dataProvider.getEmployeeInfo(employeeId);
    if(response){
      setEmployeeInfoDetail(response);
    } else {
      alert('Failed to get employee information');
    }

  }

  const handleClearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      address: "",
      phone: "",
      identifier: "",
      role_id: "",
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderModalContent = () => {
    switch (modalStatus) {
      case "success":
        return (
          <>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "green" }}
            >
              User Created Successfully!
            </DialogContentText>
            <Box>
              {Object.entries(modalContent).map(([key, value]) => (
                <Typography key={key}>{`${key}: ${value}`}</Typography>
              ))}
            </Box>
          </>
        );
      case "exists":
        return (
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "blue" }}
          >
            {modalContent}
          </DialogContentText>
        );
      case "error":
      default:
        return (
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "red" }}
          >
            {modalContent}
          </DialogContentText>
        );
    }
  };

  const renderVendorEmployees = () => (
    <Grid container spacing={2}>
      {vendorEmployees.map((employee) => (
        <Grid item xs={12} sm={6} md={4} key={employee.id}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: '#F0E5CF', fontFamily: 'sans-serif' }}>
            <CardHeader
              // avatar={
              //     <Avatar sx={{ bgcolor: '#7689B1', width: 56, height: 56 }}> 
              //       <PersonIcon sx={{ fontSize: 20 }} /> 
              //     </Avatar>
              // }
              title={`Employee ID: ${employee.id}`} 
              titleTypographyProps={{ color: 'textPrimary', fontSize:'15px' }}
              sx={{ paddingBottom: 0 , fontSize:'5px'}}
            />
            <CardContent>
            <Typography 
              variant="h6" 
              color="textPrimary" 
              sx={{ 
                fontWeight: 'medium',
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", 
                color: '#3F51B5', 
                fontSize:'1rem',
                textTransform: 'uppercase',
                whiteSpace: 'normal', 
                wordBreak: 'break-word', 
                overflowWrap: 'break-word', 
              }}
            >
              {employee.first_name} {employee.last_name}
            </Typography>
              <Typography color="textSecondary"> {/* Adjusted for contrast */}
                Member Since: {new Date(employee.created_at).toLocaleDateString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  

  return (
    
    <Container maxWidth="lg" >
      <Box my={4}>
        <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowForm(!showForm)}
        >
          {showForm ? "Hide Form" : "Create User"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{marginLeft:'5px'}}
          onClick={() => setShowEmployeeInfoFrom(!showEmployeeInfoForm)}
        >
          {showEmployeeInfoForm ? "Close" : " Get Employee Info"}
        </Button>
        {showEmployeeInfoForm && (
        <Box component="form" onSubmit={handleSubmitEmpInfo} sx={{ mt: 2 }}>
          <TextField
            label="Employee ID"
            variant="outlined"
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary" sx={{margin:'10px'}}>
            Submit
          </Button>
        </Box>
      )}
        {employeeInfoDetail && (
        <Paper elevation={3} sx={{ 
          mt: 3, 
          p: 2, 
          background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          color: 'white',
          '.MuiTypography-h6': { 
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 'bold',
          },
          '.MuiTypography-body1': {
            fontFamily:'',
            fontSize: '1rem',
            marginTop: '8px',
          },
          
          
        }}>
          <Typography variant="h6">{employeeInfoDetail.first_name} {employeeInfoDetail.last_name}</Typography>
          <Typography variant="body1">Employee ID: {employeeInfoDetail.vendor_employee_id}</Typography>
          <Typography variant="body1">Username: {employeeInfoDetail.vendor_username}</Typography>
          <Typography variant="body1">Password: {employeeInfoDetail.vendor_password}</Typography>
          <Typography variant="body1">Member Since: {new Date(employeeInfoDetail.created_at).toLocaleDateString()}</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Communication Details</Typography>
            <Typography variant="body1">Mobile: {employeeInfoDetail.communication_meta.phone}</Typography>
            <Typography variant="body1">Address: {employeeInfoDetail.communication_meta.address}</Typography>
          </Box>
        </Paper>
      )}
      
        {showForm && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            // sx={{ mt: 2 }}
          >
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Identifier "
              name="identifier"
              value={formData.identifier}
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Role</InputLabel>
              <Select
                name="role_id"
                value={formData.role_id}
                label="Role"
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.description_field}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearForm}
              >
                Clear
              </Button>
            </Box>
          </Box>
        )}
      </Grid>
          <Grid item xs={12} md={6}>
          <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", 
                fontWeight: 'bold', 
                color: '#3F51B5', 
                fontSize: '1.5rem', 
                textTransform: 'uppercase', 
                marginBottom: '20px', 
                borderBottom: '2px solid #3F51B5', 
                paddingBottom: '5px', 
              }}
            >
              Employees
          </Typography>
            {renderVendorEmployees()}
          </Grid>
      </Grid>
    </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="user-creation-status-title"
        aria-describedby="user-creation-status-description"
      >
        <DialogTitle id="user-creation-status-title">
          {"User Creation Status"}
        </DialogTitle>
        <DialogContent>{renderModalContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    
  );
};

export default UserAccess;
